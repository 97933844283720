import lodash from 'lodash';
import { SYSTEM_CURRENCY } from '@/constants';
import {
  L2ColumnAlias,
  L3ColumnAlias,
  L3ColumnIndexes,
  LookupAlias,
  LookupRowIndexes,
  SheetNames,
} from '@/enums';
import { FringeCellOrder } from '@/utils';
import FringeTotalFormula from '@/helpers/FringeTotalFormula';
import { getSuppressGroupFormula } from '@/helpers/GetSuppressGroupMultiplier';
import { IDataSheet, IL3Data } from '@/interfaces/IDataSheet';
import { IL3, IL3Sheet } from '@/interfaces/IFormulaSheet';

interface ITransformL3Sheet {
  l3DataSheet: Array<IL3Data>;
  level2RowNumber: number;
  l1SheetId: string | undefined;
  dataSheet: IDataSheet;
  fringeCellOrder: FringeCellOrder;
}

const DATA_COLUMNS_LENGTH = 27;

export const getColumnName = (index: number): string => {
  let columnName = '';
  while (index > 0) {
    const remainder = index % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    index = (index - 1 - remainder) / 26;
  }

  return columnName;
};

export const getDataColumns = (fringes: FringeCellOrder) => {
  const fringeLength = fringes?.length || 0;
  const columns: Array<string> = new Array(DATA_COLUMNS_LENGTH + fringeLength);
  return columns.fill('');
};

export const getFringeIndexByCode = (fringes: FringeCellOrder, code: string): number => {
  const index = fringes?.findIndex((fringe) => fringe === code);
  return DATA_COLUMNS_LENGTH + index + 1;
};

const getSubTotalRowIndexes = (level3Data: IL3Data[]) => {
  const subTotalRowsIndex: number[] = [];
  for (let i = 0; i < level3Data.length; i++) {
    if (level3Data[i].rowType === 'S') {
      subTotalRowsIndex.push(i);
    }
  }
  return subTotalRowsIndex;
};

const getPrevSubTotalIndex = (index: number, subTotalRowsIndex: number[]) => {
  const prevIndex = subTotalRowsIndex.findIndex((i) => i === index - 1);
  if (prevIndex === -1) {
    return -1;
  } else {
    return subTotalRowsIndex[prevIndex - 1] + 2;
  }
};

export function transformL3Sheet({
  l3DataSheet,
  level2RowNumber,
  l1SheetId,
  dataSheet,
  fringeCellOrder,
}: ITransformL3Sheet): IL3Sheet {
  if (l3DataSheet) {
    const {
      fringes: fringesSheet,
      groups: groupsSheet,
      meta: metaData,
      configs: configData,
    } = dataSheet;

    const fringeCalc = metaData.fringes.calc;

    const totalDescription = `=UPPER(IF(l2_${l1SheetId}!${L2ColumnAlias.Description}$${level2RowNumber}="","TOTAL",CONCATENATE("TOTAL FOR ",l2_${l1SheetId}!${L2ColumnAlias.Description}$${level2RowNumber})))`;

    const baseCurrency = 'BASE_CURRENCY';
    const l3DataLength = l3DataSheet?.length;
    let fringeRangeStartIndex = 1;
    let previousFringeRangeId = '';
    const subTotalRowsIndex: number[] = getSubTotalRowIndexes(l3DataSheet);
    const l3FormulaSheet: IL3Sheet = l3DataSheet.map((l3Data: IL3Data, li: number): IL3 => {
      const index: number = li + 1;
      const cu = l3Data.cu?.startsWith('=') ? l3Data.cu.substring(1) : l3Data.cu;
      const desc = l3Data.desc?.startsWith('=') ? l3Data.desc.substring(1) : l3Data.desc;
      const units = `${l3Data.units}`?.startsWith('=')
        ? `${l3Data.units}`?.substring(1)
        : l3Data?.units;

      const totalColumn = L3ColumnAlias.Total;
      const comparisonColumn = L3ColumnAlias.Comparison;
      const varianceColumn = L3ColumnAlias.Variance;
      const fringeTotalColumn = L3ColumnAlias.FringesTotal;
      const rowTypeColumn = L3ColumnAlias.RowType;
      const fringeComparisonColumn = L3ColumnAlias.FringeComparison;

      const prevIndex = index - 1;
      const prevSubTotalIndex = getPrevSubTotalIndex(index, subTotalRowsIndex);
      const startIndex = prevIndex === subTotalRowsIndex[0] ? 1 : prevSubTotalIndex;

      const getSuppressFormula = getSuppressGroupFormula(l3Data, groupsSheet);

      // UNDER OVER CALCULATION
      const underOver = `=IF(AND(${varianceColumn}${index}>-1,${varianceColumn}${index}<1), 0, INT((${varianceColumn}${index}/${L3ColumnAlias.MaxVariance}${index}) * 100))`;

      // TOTAL CALCULATION
      const total = `=IF(${L3ColumnAlias.Desc}${index}="%",${L3ColumnAlias.Units}${index}/100,${
        L3ColumnAlias.Units
      }${index})*${L3ColumnAlias.X}${index}*${cu === '' ? SYSTEM_CURRENCY : cu}/${baseCurrency}*${
        L3ColumnAlias.Rate
      }${index} * ${getSuppressFormula} `;

      const fringeCompaison = `=${l3Data.fringeComparison || 0} * ${SheetNames.LOOKUP}!${
        LookupAlias.Value
      }${LookupRowIndexes.dropCurrencyAtReset}/${baseCurrency}`;

      const subTotal = `=SUM(${totalColumn}${startIndex}:${totalColumn}${prevIndex})`;

      const netTotal = `=SUMIF(${rowTypeColumn}1:${rowTypeColumn}${
        l3DataLength - 1
      },"D",${totalColumn}1:${totalColumn}${l3DataLength - 1})`;

      const fringeTotal = `=SUMIF(${L3ColumnAlias.RowType}:${L3ColumnAlias.RowType}, "D", ${L3ColumnAlias.FringesTotal}:${L3ColumnAlias.FringesTotal})`;

      const combinedFormulaForTotal = `=SUMIF(${rowTypeColumn}1:${rowTypeColumn}${
        l3DataLength - 1
      },"D",${totalColumn}1:${totalColumn}${l3DataLength - 1}) + SUMIF(${L3ColumnAlias.RowType}:${
        L3ColumnAlias.RowType
      },"D",${L3ColumnAlias.FringesTotal}:${L3ColumnAlias.FringesTotal})`;

      // VARIANCE CALCULATION
      const isNegativeVariance = configData?.varianceCalc === 'under';
      const sign = isNegativeVariance ? 1 : -1;
      const variance = `=ABS(${totalColumn}${index}-${comparisonColumn}${index})*IF(${totalColumn}${index}>${comparisonColumn}${index}, ${sign}, ${-sign})`;
      const varianceTotal = `=SUM(${varianceColumn}${startIndex}:${varianceColumn}${prevIndex})`;
      //get T row and reduce comparison from total

      // MAXIMUM VARIANCE CALCULATION
      const maxIntVariance = `=MAX(-MINIFS(${varianceColumn}:${varianceColumn},${rowTypeColumn}:${rowTypeColumn},"D"),MAXIFS(${varianceColumn}:${varianceColumn},${rowTypeColumn}:${rowTypeColumn},"D"))`;

      // COMPARISON CALCULATION MAKE IT CURRENCY CONVERTED
      const comparison = `=${l3Data.comparison || 0} * ${SheetNames.LOOKUP}!${LookupAlias.Value}${
        LookupRowIndexes.dropCurrencyAtReset
      }/${baseCurrency}`;

      const comparisonTotal = `=SUM(${comparisonColumn}${startIndex}:${comparisonColumn}${prevIndex})`;

      const combinedFormulaForComparison = `=SUMIF(${rowTypeColumn}1:${rowTypeColumn}${
        l3DataLength - 1
      },"D",${comparisonColumn}1:${comparisonColumn}${
        l3DataLength - 1
      }) + SUMIF(${rowTypeColumn}1:${rowTypeColumn}${
        l3DataLength - 1
      },"D",${fringeComparisonColumn}1:${fringeComparisonColumn}${l3DataLength - 1})`;

      const netComparison = `=SUMIF(${rowTypeColumn}1:${rowTypeColumn}${
        l3DataLength - 1
      },"D",${comparisonColumn}1:${comparisonColumn}${l3DataLength - 1})`;

      const l3Row: IL3 = getDataColumns(fringeCellOrder);
      l3Row[L3ColumnIndexes.id] = l3Data.id;
      l3Row[L3ColumnIndexes.rowType] = l3Data.rowType;
      l3Row[L3ColumnIndexes.index] = `=ROW(${L3ColumnAlias.UnderOver}${index})`;

      switch (l3Data.rowType) {
        //Sub-total
        case 'S':
          l3Row[L3ColumnIndexes.description] = l3Data.description;
          l3Row[L3ColumnIndexes.total] = subTotal;
          l3Row[L3ColumnIndexes.comparison] = comparisonTotal;
          l3Row[L3ColumnIndexes.variance] = varianceTotal;
          l3Row[L3ColumnIndexes.maxVariance] = 0;
          l3Row[L3ColumnIndexes.fringeTotal] = 0;
          break;

        //Net Total
        case 'T':
          l3Row[L3ColumnIndexes.loc] = `'Total`;
          l3Row[L3ColumnIndexes.set] = `'Total`;
          l3Row[L3ColumnIndexes.cu] = `'Total`;
          l3Row[L3ColumnIndexes.description] = totalDescription;
          l3Row[L3ColumnIndexes.total] =
            fringeCalc === 'account' ? combinedFormulaForTotal : netTotal;
          l3Row[L3ColumnIndexes.comparison] =
            fringeCalc === 'account' ? combinedFormulaForComparison : netComparison;
          l3Row[L3ColumnIndexes.variance] = variance;
          l3Row[L3ColumnIndexes.maxVariance] = 0;
          l3Row[L3ColumnIndexes.fringeTotal] = 0;
          //@TODO: Refactor
          fringeCellOrder.forEach((fringe) => {
            const fringeIndex = getFringeIndexByCode(fringeCellOrder, fringe);
            const fringeAlias = getColumnName(fringeIndex + 1);
            l3Row[fringeIndex] = `=SUM(${fringeAlias}1:${fringeAlias}${l3DataLength - 2})`;
          });
          break;
        // Fringe Total
        case 'F':
          l3Row[L3ColumnIndexes.description] = l3Data.description;
          l3Row[L3ColumnIndexes.total] = fringeTotal;
          l3Row[L3ColumnIndexes.loc] = `'${l3Data.loc}`;
          l3Row[L3ColumnIndexes.set] = `'${l3Data.set}`;
          l3Row[L3ColumnIndexes.comparison] =
            `=SUMIF(${L3ColumnAlias.RowType}:${L3ColumnAlias.RowType}, "D", ${L3ColumnAlias.FringeComparison}:${L3ColumnAlias.FringeComparison})`;
          l3Row[L3ColumnIndexes.variance] = variance;

          //@Refactor
          fringeCellOrder.forEach((fringe) => {
            const fringeIndex = getFringeIndexByCode(fringeCellOrder, fringe);
            const fringeAlias = getColumnName(fringeIndex + 1);
            l3Row[fringeIndex] = `=COUNTIF(${fringeAlias}1:${fringeAlias}${
              l3DataLength - 2
            },">=0") + COUNTIF(${fringeAlias}1:${fringeAlias}${l3DataLength - 2},"<0")`;
          });
          break;

        default:
          l3Row[L3ColumnIndexes.underOver] = underOver;
          l3Row[L3ColumnIndexes.range] = l3Data.range;
          l3Row[L3ColumnIndexes.fringe] = l3Row[L3ColumnIndexes.fringe] =
            `=IF(${totalColumn}${index}=0,"",${fringeTotalColumn}${index}/${totalColumn}${index})`;
          l3Row[L3ColumnIndexes.fringes] = l3Data.fringes;
          l3Row[L3ColumnIndexes.groups] = l3Data.groups;
          l3Row[L3ColumnIndexes.loc] = `'${l3Data.loc}`;
          l3Row[L3ColumnIndexes.set] = `'${l3Data.set}`;
          l3Row[L3ColumnIndexes.description] = l3Data.description;
          l3Row[L3ColumnIndexes.units] = l3Data.units;
          l3Row[L3ColumnIndexes.desc] = l3Data.desc;
          l3Row[L3ColumnIndexes.x] = l3Data.x;
          l3Row[L3ColumnIndexes.rate] = l3Data.rate;
          l3Row[L3ColumnIndexes.cu] = cu;
          l3Row[L3ColumnIndexes.total] = total;
          l3Row[L3ColumnIndexes.comparison] = comparison;
          l3Row[L3ColumnIndexes.variance] = variance;
          l3Row[L3ColumnIndexes.maxVariance] = maxIntVariance;
          l3Row[L3ColumnIndexes.fringeTotal] = 0;
          l3Row[L3ColumnIndexes.groupSuppress] = `=${getSuppressFormula}`;
          l3Row[L3ColumnIndexes.unitFormula] =
            `=IF(ISFORMULA(${L3ColumnAlias.Units}${index}),FORMULATEXT(${L3ColumnAlias.Units}${index}), ${L3ColumnAlias.Units}${index})`;
          l3Row[L3ColumnIndexes.xFormula] =
            `=IF(ISFORMULA(${L3ColumnAlias.X}${index}),FORMULATEXT(${L3ColumnAlias.X}${index}), ${L3ColumnAlias.X}${index})`;
          l3Row[L3ColumnIndexes.rateFormula] =
            `=IF(ISFORMULA(${L3ColumnAlias.Rate}${index}),FORMULATEXT(${L3ColumnAlias.Rate}${index}), ${L3ColumnAlias.Rate}${index})`;
          l3Row[L3ColumnIndexes.concatenateUnitXRate] =
            `=CONCATENATE(${L3ColumnAlias.UnitFormula}${index}&","&${L3ColumnAlias.XFormula}${index}&","&${L3ColumnAlias.RateFormula}${index})`;
          l3Row[L3ColumnIndexes.fringeComparison] = fringeCompaison;

          if (l3Data.fringes) {
            l3Data.fringes.split(',').forEach((fringe: string) => {
              const fringeIndex = getFringeIndexByCode(fringeCellOrder, fringe);
              const fringeAlias = getColumnName(fringeIndex);
              const fringeFormulaBuilder = new FringeTotalFormula(fringesSheet);
              fringeFormulaBuilder
                .withFringesString(fringe)
                .withCurrency(cu || `${SYSTEM_CURRENCY}`)
                .withIndex(index)
                .withTotalRef(`${L3ColumnAlias.Total}${li + 1}`)
                .withUnitDesc(desc)
                .withUnits(units);

              if (
                lodash.isEmpty(l3Data.range) ||
                l3Data.range !== previousFringeRangeId ||
                index === 1
              ) {
                //fringeRangeStartIndex = index - 1;
                fringeRangeStartIndex = index;
                //previousFringeRangeId = l3Data.range;
                fringeFormulaBuilder.withRangeStart(true);
              } else {
                fringeFormulaBuilder.withRangeStart(false);

                fringeFormulaBuilder.withPreviousFringeRef(
                  `${fringeAlias}${fringeRangeStartIndex}:${fringeAlias}${index - 1}`,
                );

                fringeFormulaBuilder.withPreviousL3UnitsRef(
                  `${L3ColumnAlias.Units}${fringeRangeStartIndex}:${L3ColumnAlias.Units}${
                    index - 1
                  }`,
                );
              }
              l3Row[fringeIndex] = fringeFormulaBuilder.build();
            });

            //* Moved outside the foo loop */
            previousFringeRangeId = l3Data.range;

            const fringeColumnStart = getColumnName(DATA_COLUMNS_LENGTH);
            const fringeColumnEnd = getColumnName(DATA_COLUMNS_LENGTH + fringesSheet.length + 1);
            l3Row[L3ColumnIndexes.fringeTotal] =
              `=SUM(${fringeColumnStart}${index}:${fringeColumnEnd}${index})`;
          }
          break;
      }

      return l3Row;
    });

    return l3FormulaSheet;
  }
  return [] as IL3Sheet;
}
