import { FringesColumnIndexes, SheetNames } from '@/enums';
import NamedExpressionRules, { StyledCode } from '@/components/common/NamedExpressionRules';
import { ICodeValidation } from '@/interfaces/ICodeValidation';
import { IDataSheet } from '@/interfaces/IDataSheet';
import FormulaSheet from '@/sheets/FormulaSheet';
import { isCodeAlreadyExist } from './dataSheet';

const SHEETS_TO_FORMAT: SheetNames[] = [
  SheetNames.GLOBALS,
  SheetNames.GROUPS,
  SheetNames.UNIT_DESCRIPTIONS,
];

const SHEETS_WITHOUT_CODE_EXPRESSION_VALIDATION: SheetNames[] = [
  SheetNames.LOCATIONS,
  SheetNames.SETS,
];

export const validateAndFormatCodeCell = (
  code: string,
  data: Array<string | number | null>,
  dataSheet: IDataSheet | null,
  sheetName: keyof IDataSheet,
  formulaSheet?: FormulaSheet,
): ICodeValidation => {
  if (!dataSheet) return { isValidCode: false, message: '', code };

  if (code === '') {
    return {
      isValidCode: false,
      code,
      message: <p>Unable to set this empty as this code is been used in calculations.</p>,
    };
  }

  if (isCodeAlreadyExist(dataSheet, sheetName, code)) {
    return {
      isValidCode: false,
      code,
      message: (
        <p>
          <StyledCode>{code}</StyledCode> code has already been used.
        </p>
      ),
    };
  }

  if (
    data &&
    !formulaSheet?.isItPossibleToAddNamedExpression(sheetName, code, data) &&
    SHEETS_TO_FORMAT.includes(sheetName)
  ) {
    let newCode = code;

    // Add an underscore to the end of code if starting with a letter.
    if (code[0].match(/[a-z]/i)) {
      newCode += '_';
    }
    // Add letter “A” to the start of codes if starting with a number.
    if (code[0].match(/[0-9]/)) {
      newCode = 'X' + newCode;
    }
    // Replace any spaces with periods (full stops).
    newCode = newCode.replace(/ /g, '.');
    // Remove any comma in code.
    newCode = newCode.replace(/,/g, '');
    // Replace any invalid symbols like @, $, #, etc. with underscores.
    newCode = newCode.replace(/[^a-zA-Z0-9_.]/g, '_');

    return {
      isValidCode: true,
      autoFormattedCode: true,
      code: newCode,
      message: '',
    };
  }

  const fringesIndex =
    sheetName === SheetNames.FRINGES
      ? dataSheet.fringes.findIndex((item) => item.id === data[FringesColumnIndexes.id])
      : undefined;

  if (
    data &&
    !formulaSheet?.isItPossibleToAddNamedExpression(sheetName, code, data, fringesIndex) &&
    !SHEETS_WITHOUT_CODE_EXPRESSION_VALIDATION.includes(sheetName)
  ) {
    return {
      isValidCode: false,
      code,
      message: (
        <p>
          <div>
            <StyledCode>{code}</StyledCode> is invalid expression name.
          </div>{' '}
          <NamedExpressionRules />
        </p>
      ),
    };
  }

  return {
    isValidCode: true,
    code,
    message: '',
  };
};
