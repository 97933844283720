import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Grid, Typography } from '@mui/material';
import { registerAllModules } from 'handsontable/registry';
import lodash from 'lodash';
import { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SYSTEM_CURRENCY_LABEL } from '@/constants';
import {
  APP_PATH,
  AUTH_PATH,
  DATA_SHEET_FILE_TYPES,
  LocalStorageVariables,
  S3ObjectType,
  TabIndexes,
  UserTypes,
} from '@/enums';
import { useAggregations, useGlobalSearchService, useSubscription, useOpenedFiles } from '@/hooks';
import {
  getExtensionFromFilename,
  getFileType,
  getSession,
  logout,
  removedExtensionsFilename,
} from '@/utils';
import { SUBSCRIPTION_COST_TRACKER_LEVEL } from '@/app/constants/subscription';
import {
  aggregationsAtom,
  dataSheetAtom,
  initialSelectedSheet,
  selectedSheetIdsAtom,
} from '@/atoms/DataSheetAtom';
import 'handsontable/dist/handsontable.min.css';
import {
  budgetSheetSavedResponseAtom,
  budgetSheetSharedResponseAtom,
  currentTabIndexAtom,
  fileUrlAtom,
  isDataSheetInitializingAtom,
  isNewlySavedFile,
  openFileDialogAtom,
  openFileTypeAtom,
  pageTitleDetailsAtom,
  reportDialogBoxAtom,
  searchAccountSelectedResultAtom,
  selectedCurrencyAtom,
  sideBarToggleAtom,
  userRoleAtom,
} from '@/atoms/GlobalAtoms';
import {
  useToaster,
  SuspenseSpinner,
  FullScreenSpinner,
  FileProgressSpinner,
} from '@/components/common';
import { IFileResponse } from '@/components/dialogBoxes/types';
import {
  AccountIcon,
  ClipboardIcon,
  CloseSquareIcon,
  DollarSquareIcon,
  Export,
  HelpIcon,
  ImportIcon,
  LogoutIcon,
  ReceiveSquareIcon,
  ReportIcon,
  RotateLeftIcon,
  SendSquareIcon,
  ShareIcon,
} from '@/components/icon';
import { Menu } from '@/components/layout/Menu';
import { Tabs } from '@/components/layout/Tabs';
import { useCostTracker } from '@/contexts/CostTrackerContext';
import { ICurrencyImport } from '@/interfaces/IImportData';
import { logger } from '@/lib/logger';
import DataSheetApiService from '@/services/DataSheetApiService';
import * as AggregationCalculator from '@/sheets/Aggregations';
import FormulaSheet, {
  IFormulaSheetBuildEvent,
  IFormulaSheetChangeEvent,
} from '@/sheets/FormulaSheet';

import { SheetContainer } from './AppLayout.styled';
import { getTabs, returnFileName, validateSheetMetaData } from './AppLayout.utils';
import { MenuOption } from './Menu/Menu.types';
import { useConfirmationDialog } from '../dialogBoxes';

// Dialog imports
const OpenFileDialog = lazy(() => import('@/components/dialogBoxes/OpenFileDialog/OpenFileDialog'));
const ExportDialog = lazy(() => import('@/components/dialogBoxes/ExportDialog'));
const ImportDialog = lazy(() => import('@/components/dialogBoxes/ImportDialog'));
const SaveAsDialog = lazy(() => import('@/components/dialogBoxes/SaveAsDialog/SaveAsDialog'));
const ConfirmationDialog = lazy(
  () => import('@/components/dialogBoxes/ConfirmationDialog/ConfirmationDialog'),
);
const AccountDialogBox = lazy(() =>
  import('@/components/dialogBoxes/AccountDialogBox/index').then((module) => ({
    default: module.AccountDialogBox,
  })),
);
const BudgetOptionDialog = lazy(() =>
  import('@/components/dialogBoxes/BudgetOptionDialog/index').then((module) => ({
    default: module.BudgetOptionDialog,
  })),
);
const CostTrackerOptionDialog = lazy(() =>
  import('@/components/dialogBoxes/CostTrackerOptionDialog/index').then((module) => ({
    default: module.CostTrackerOptionDialog,
  })),
);
const ReportDialogBox = lazy(() =>
  import('@/components/dialogBoxes/ReportDialogBox').then((module) => ({
    default: module.ReportDialogBox,
  })),
);
const ShareDialogBox = lazy(() =>
  import('@/components/dialogBoxes/ShareDialogBox/index').then((module) => ({
    default: module.ShareDialogBox,
  })),
);
const SystemListDialog = lazy(() =>
  import('@/components/dialogBoxes/SystemListDialog/index').then((module) => ({
    default: module.SystemListDialog,
  })),
);

interface OutletContext {
  formulaSheet: FormulaSheet;
  // /* Temporarily disabled */ undoRedoService: UndoRedoService;
  useResetVariance: boolean;
  setUseResetVariance: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenReport: React.Dispatch<React.SetStateAction<boolean>>;
  onLogout: () => void;
}

registerAllModules();

const AppLayout = () => {
  const aggregationsProcessor = useAggregations();
  const navigate = useNavigate();
  const [handleDataSheetChanges, setHandleDataSheetChanges] = useState<{
    isDataSheetChangesAvailable: boolean;
    menuOptions: string;
  }>({ isDataSheetChangesAvailable: false, menuOptions: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [fileUrlName, setFileUrlName] = useRecoilState(fileUrlAtom);
  const [sideBarShow, setSideBarToggle] = useRecoilState(sideBarToggleAtom);
  const [dataSheet, setDataSheet] = useRecoilState(dataSheetAtom);
  const [aggregations, setAggregations] = useRecoilState(aggregationsAtom);
  const {
    costTrackerOptions: { costTrackerMode },
    resetCostTrackerOptions,
  } = useCostTracker();

  const [openFileType, setOpenFileType] = useRecoilState(openFileTypeAtom);
  const setSelectedCurrency = useSetRecoilState(selectedCurrencyAtom);
  const [newlySavedFile, setIsNewlySavedFile] = useRecoilState(isNewlySavedFile);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [isDataSheetInitializing, setIsDataSheetInitializing] = useRecoilState(
    isDataSheetInitializingAtom,
  );
  const [isDataSheetFetchingFromRemote, setIsDataSheetFetchingFromRemote] = useState(false);
  const [isDataSheetFetchingFromCache, setIsDataSheetFetchingFromCache] = useState(false);

  const { openDialog, confirmationDialogProps } = useConfirmationDialog();
  const { addFileInstanceToStorage, removeOpenedFilesFromStorage } = useOpenedFiles();
  const [newFileNameUrl, setNewFileNameUrl] = useState<string>();
  const [saveAsOpen, setSaveAsOpen] = useState<boolean>(false);
  const [openFileOpen, setOpenFileOpen] = useRecoilState(openFileDialogAtom);
  const [useResetVariance, setUseResetVariance] = useState(false);
  const [confirmResetVariance, setConfirmResetVariance] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [openReport, setOpenReport] = useRecoilState(reportDialogBoxAtom);
  const [openSystemList, setOpenSystemList] = useState(false);
  const [openBudgetOption, setOpenBudgetOption] = useState(false);
  const [openCostTrackerOption, setOpenCostTrackerOption] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const userRole = useRecoilValue(userRoleAtom);
  const [openImport, setOpenImport] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const budgetSheetSharedResponse = useRecoilValue(budgetSheetSharedResponseAtom);
  const [budgetSheetSavedResponse, setBudgetSheetSavedResponse] = useRecoilState(
    budgetSheetSavedResponseAtom,
  );

  const username = localStorage.getItem(LocalStorageVariables.ROOT_PATH);

  const formulaSheet = useRef(FormulaSheet.getInstance());
  // Temporarily disabled
  // const undoRedoService = useUndoRedoService();

  const [disableAllActions, setDisableAllActions] = useState<boolean>(false);
  const [fileSaveLoading, setFileSaveLoading] = useState(false);
  const [fileOpenStatus, setFileOpenStatus] = useState('');
  const setPageTitleDetails = useSetRecoilState(pageTitleDetailsAtom);
  const setSelectedSheet = useSetRecoilState(selectedSheetIdsAtom);
  const setCurrentTabIndex = useSetRecoilState(currentTabIndexAtom);
  // Global Search Variables
  const globalSearchService = useGlobalSearchService();

  const { subscriptionLevel } = useSubscription();

  // Go To Search Variables
  const setSearchAccountSelectedResultAtom = useSetRecoilState(searchAccountSelectedResultAtom);
  const aggregationProcessor = useAggregations();
  const snackBar = useToaster();

  const triggerSnackBar = (action: string) => {
    if (action === 'success') {
      setBudgetSheetSavedResponse({
        isOpenSnackBar: true,
        message: 'Successfully saved',
        severity: 'success',
      });
    } else if (action === 'error') {
      setBudgetSheetSavedResponse({
        isOpenSnackBar: true,
        message: 'Error saving budget Sheet',
        severity: 'error',
      });
    }
  };

  const handleDataSheetSave = useCallback(
    async (method: 'manual' | 'auto') => {
      const filePath = localStorage.getItem(LocalStorageVariables.ROOT_PATH);
      const isDataSheetAvailableOnCache = await formulaSheet.current.isDataSheetAvailableOnCache();
      const isDataSheetPendingSyncing = await formulaSheet.current.isDataSheetPendingSyncing();

      if (disableAllActions || !filePath || !fileUrlName || !dataSheet) return;
      let s3ObjectType: S3ObjectType | undefined;
      if (method === 'manual') {
        setFileSaveLoading(true);
        s3ObjectType = S3ObjectType.JSON_FILE;
      } else if (method === 'auto' && isDataSheetAvailableOnCache && isDataSheetPendingSyncing) {
        s3ObjectType = S3ObjectType.TEMPORARY_FILE;
      }

      if (!s3ObjectType) return;
      const savePath = returnFileName(method, fileUrlName);
      try {
        await formulaSheet.current.saveDataSheetInFolderOnRemote(
          filePath,
          savePath,
          s3ObjectType,
          dataSheet,
        );
        await formulaSheet.current.turnOffRemoteSyncing();
        if (method === 'manual') {
          triggerSnackBar('success');
        }
      } catch (e) {
        triggerSnackBar('error');
      }
      setFileSaveLoading(false);
    },
    [fileUrlName, dataSheet, disableAllActions],
  );

  const handleLogout = () => {
    setOpenLogout(true);
  };

  const clearDateSheetCache = useCallback(() => {
    globalSearchService.clearAllResults();
    globalSearchService.clearSearchQuery();
    setSearchAccountSelectedResultAtom(undefined);
  }, [globalSearchService, setSearchAccountSelectedResultAtom]);

  const menuOptions: MenuOption[] = [
    {
      icon: <SendSquareIcon fontSize="small" stroke={'black'} />,
      label: 'Open',
      id: 'open',
      action: async () => {
        const isDataSheetChangesAvailable = await formulaSheet.current.isDataSheetPendingSyncing();
        // validating changes exist in budget sheet
        setHandleDataSheetChanges({ isDataSheetChangesAvailable, menuOptions: 'open' });
        setOpenFileOpen(!isDataSheetChangesAvailable);
      },
    },
    {
      icon: <ReceiveSquareIcon fontSize="small" stroke={'black'} />,
      label: 'Save',
      id: 'save',
      action: async () => {
        await handleDataSheetSave('manual');
      },
      disabled: disableAllActions,
    },
    {
      icon: <ReceiveSquareIcon fontSize="small" stroke={'black'} />,
      label: 'Save As',
      id: 'saveAs',
      action: () => {
        setSaveAsOpen(true);
      },
    },
    {
      icon: <ShareIcon fontSize="small" stroke={'black'} />,
      label: 'Share',
      id: 'share',
      action: () => {
        setOpenShareDialog(true);
      },
    },
    {
      icon: <ReportIcon fontSize="small" stroke={'black'} />,
      label: 'Reports',
      id: 'reports',
      action: () => {
        setOpenReport(true);
      },
      disabled: disableAllActions,
    },
    {
      icon: <DollarSquareIcon fontSize="small" stroke={'black'} />,
      label: 'Budget Options',
      id: 'budgetOptions',
      action: () => {
        setOpenBudgetOption(true);
      },
    },
    ...(subscriptionLevel === SUBSCRIPTION_COST_TRACKER_LEVEL
      ? [
          {
            icon: <RequestQuoteIcon fontSize="small" stroke="black" />,
            label: 'Cost Tracker',
            id: 'costTrackerOptions',
            action: () => {
              setOpenCostTrackerOption(true);
            },
          },
        ]
      : []),
    {
      icon: <Export fontSize="small" stroke={'black'} />,
      label: 'Export',
      id: 'Export',
      action: () => {
        setOpenExport(true);
      },
    },
    {
      icon: <ImportIcon fontSize="small" stroke={'black'} />,
      label: 'Import',
      id: 'Import',
      action: () => {
        setOpenImport(true);
      },
    },
    {
      icon: <RotateLeftIcon fontSize="small" stroke={'black'} />,
      label: 'Reset Variance',
      id: 'resetVariance',
      action: () => {
        setConfirmResetVariance(true);
      },
      disabled: disableAllActions,
    },
    {
      icon: <CloseSquareIcon fontSize="small" sx={{ stroke: 'black' }} />,
      label: 'Close',
      id: 'close',
      action: async () => {
        const isDataSheetChangesAvailable = await formulaSheet.current.isDataSheetPendingSyncing();

        if (!isDataSheetChangesAvailable) {
          handleCloseBudget();
          return;
        }
        setHandleDataSheetChanges({ isDataSheetChangesAvailable, menuOptions: 'close' });
      },
    },
    {
      icon: <AccountIcon fontSize="small" stroke={'black'} />,
      label: 'Account',
      id: 'account',
      action: () => {
        setOpenAccount(true);
      },
    },
    ...(userRole === UserTypes.ADMIN
      ? [
          {
            icon: <ClipboardIcon fontSize="small" stroke={'black'} />,
            label: 'System List',
            id: 'systemList',
            action: () => {
              setOpenSystemList(true);
            },
          },
        ]
      : []),
    {
      icon: <HelpIcon fontSize="small" stroke={'black'} />,
      label: 'Help',
      id: 'help',
      action: () => {
        //open a URL in new tab
        window.open('https://www.tphglobal.com', '_blank');
      },
    },
    {
      icon: <LogoutIcon fontSize="small" stroke={'black'} />,
      label: 'Logout',
      id: 'logout',
      action: async () => {
        const isDataSheetChangesAvailable = await formulaSheet.current.isDataSheetPendingSyncing();
        // validating changes exist in budget sheet
        setHandleDataSheetChanges({ isDataSheetChangesAvailable, menuOptions: 'logout' });
        setOpenLogout(!isDataSheetChangesAvailable);
      },
    },
  ];

  const updateDataSheetAfterAggregationFinish = useCallback(async () => {
    if (dataSheet && aggregations) {
      const aggregationsMap = AggregationCalculator.getAggregationMap(aggregations);
      await formulaSheet.current.updateAggregations(aggregationsMap);
      setDataSheet({ ...dataSheet });
    }
  }, [aggregations, dataSheet, setDataSheet]);

  useEffect(() => {
    const fs = formulaSheet.current;

    const fetchUserData = async () => {
      const session = await getSession();

      if (session?.['custom:forcePasswordReset'] === 'true') {
        return navigate(AUTH_PATH.forceDetailsChange);
      } else if (fileUrlName === null || fileUrlName === '') {
        setIsDataSheetInitializing(false);
        setIsLoading(false);
        return navigate(APP_PATH.welcome);
      } else {
        setIsLoading(false);
      }
    };

    fetchUserData();

    const onL3SheetAdded = (event: IFormulaSheetChangeEvent) => {
      console.debug('L3 Sheet Added', event);
    };

    const onL3SheetModified = (event: IFormulaSheetChangeEvent) => {
      console.debug('L3 Sheet Modified', event);
    };

    const onL3SheetRemoved = (event: IFormulaSheetChangeEvent) => {
      console.debug('L3 Sheet Removed', event);
    };

    const onBudgetSheetBuild = (event: IFormulaSheetChangeEvent | IFormulaSheetBuildEvent) => {
      const { status } = event as IFormulaSheetBuildEvent;
      switch (status) {
        case 'TRANSFORMING':
          setFileOpenStatus('Transforming data...');
          break;
        case 'BUILDING':
          setFileOpenStatus('Loading formula...');
          break;
        case 'COMPLETED':
          setFileOpenStatus('Loading Completed');
          lodash.debounce(() => {
            setFileOpenStatus('');
          }, 500);
          break;
        default:
          setFileOpenStatus('');
          break;
      }
    };

    fs.on('L3SheetAdded', onL3SheetAdded);
    fs.on('L3SheetModified', onL3SheetModified);
    fs.on('L3SheetRemoved', onL3SheetRemoved);
    fs.on('Build', onBudgetSheetBuild);

    return () => {
      fs.off('L3SheetAdded', onL3SheetAdded);
      fs.off('L3SheetModified', onL3SheetModified);
      fs.off('L3SheetRemoved', onL3SheetRemoved);
      fs.off('Build', onBudgetSheetBuild);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSheet && !isDataSheetFetchingFromRemote && !isDataSheetFetchingFromCache) {
      (async () => await formulaSheet.current.saveDataSheetOnCache(dataSheet))();
    }
  }, [dataSheet]);

  useEffect(() => {
    updateDataSheetAfterAggregationFinish();
  }, [aggregations]);

  useEffect(() => {
    if (fileUrlName === null) return;
    if ((fileUrlName.includes('.json') || fileUrlName.includes('.tmpl')) && !newlySavedFile) {
      localStorage.setItem(LocalStorageVariables.FILE_NAME, fileUrlName);
      setOpenFileType(getFileType(fileUrlName));

      (async () => await loadDataSheet())();
    }
  }, [fileUrlName]);

  useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        console.debug('Auto Saving to remote database...');
        await handleDataSheetSave('auto');
      })();
    }, 1000 * 360); // 6 minutes
    return () => clearInterval(interval);
  }, [handleDataSheetSave]);

  useEffect(() => {
    if (openFileType === DATA_SHEET_FILE_TYPES.JSON) {
      setDisableAllActions(false);
    } else {
      setDisableAllActions(true);
    }
  }, [openFileType]);

  useEffect(() => {
    if (budgetSheetSavedResponse.isOpenSnackBar) {
      switch (budgetSheetSavedResponse.severity) {
        case 'success':
          snackBar.success(budgetSheetSavedResponse.message);
          break;
        case 'warning':
          snackBar.warning(budgetSheetSavedResponse.message);
          break;
        case 'error':
          snackBar.error(budgetSheetSavedResponse.message);
          break;
        default:
          snackBar.info(budgetSheetSavedResponse.message);
          break;
      }
    }
  }, [budgetSheetSavedResponse]);

  useEffect(() => {
    if (budgetSheetSharedResponse.isOpenSnackBar) {
      switch (budgetSheetSharedResponse.severity) {
        case 'success':
          snackBar.success(budgetSheetSharedResponse.message);
          break;
        case 'warning':
          snackBar.warning(budgetSheetSharedResponse.message);
          break;
        case 'error':
          snackBar.error(budgetSheetSharedResponse.message);
          break;
        default:
          snackBar.info(budgetSheetSharedResponse.message);
          break;
      }
    }
  }, [budgetSheetSharedResponse]);

  const loadDataSheetIfAvailableOnCache = useCallback(async () => {
    setIsDataSheetFetchingFromCache(true);

    console.debug('-------------- Loading Data Sheet From Cache --------------');
    try {
      setTimeout(() => {
        (async () => {
          const sheet = await formulaSheet.current.getLocalDataSheetOnCache();
          const { dataSheet, defaultCurrencyCode } = validateSheetMetaData(sheet);

          setSelectedCurrency(defaultCurrencyCode);
          setDataSheet(dataSheet);
          await formulaSheet.current.fromDataSheet(dataSheet);
          const updatedAggregations = await aggregationsProcessor.calculate(
            formulaSheet.current.getAllSheetValues(),
            formulaSheet.current.getL3FringesCellOrder(),
          );
          setAggregations(updatedAggregations);
          setIsDataSheetInitializing(false);
          setIsDataSheetFetchingFromCache(false);
        })();
      }, 100);
    } catch (error) {
      handleOpenLoadingErrorDialog();
      setIsDataSheetInitializing(false);
      setIsDataSheetFetchingFromCache(false);
      removeOpenedFilesFromStorage(fileUrlName);
      setFileUrlName(null);
      console.error('File not found or unable to load the file', error);
    }

    setIsNewlySavedFile(false);
  }, [
    setIsNewlySavedFile,
    setSelectedCurrency,
    setDataSheet,
    aggregationsProcessor,
    setAggregations,
    setIsDataSheetInitializing,
  ]);

  const loadDataSheetIfNotAvailableOnCache = useCallback(async () => {
    setIsDataSheetFetchingFromRemote(true);

    if (!username || !fileUrlName) {
      handleOpenLoadingErrorDialog();
      setIsDataSheetInitializing(false);
      setIsDataSheetFetchingFromRemote(false);
      removeOpenedFilesFromStorage(fileUrlName);
      return;
    }
    console.debug('-------------- Loading Data Sheet From Remote --------------');

    try {
      await formulaSheet.current.clearDataSheetOnCache();
      const responseFileDetails = await DataSheetApiService.loadPresignedData(
        username,
        fileUrlName,
      );

      setTimeout(() => {
        (async () => {
          const currentFileExtension = getExtensionFromFilename(fileUrlName);
          if (!currentFileExtension) {
            handleOpenLoadingErrorDialog();
            setIsDataSheetInitializing(false);
            setIsDataSheetFetchingFromRemote(false);
            removeOpenedFilesFromStorage(fileUrlName);
            return;
          }

          const fileMapping: { [key in DATA_SHEET_FILE_TYPES]?: string } = {
            [DATA_SHEET_FILE_TYPES.TEMP]: responseFileDetails.tempFilePath,
            [DATA_SHEET_FILE_TYPES.TMPL]: responseFileDetails.tmplFilePath,
            [DATA_SHEET_FILE_TYPES.JSON]: responseFileDetails.jsonFilePath,
          };

          const filePath = fileMapping[currentFileExtension];
          if (!filePath) {
            handleOpenLoadingErrorDialog();
            setIsDataSheetInitializing(false);
            setIsDataSheetFetchingFromRemote(false);
            removeOpenedFilesFromStorage(fileUrlName);
            return;
          }

          const sheet = await formulaSheet.current.loadDataSheetFromPresignedUrl(filePath);

          if (!sheet) {
            handleOpenLoadingErrorDialog();
            setIsDataSheetInitializing(false);
            setIsDataSheetFetchingFromRemote(false);
            removeOpenedFilesFromStorage(fileUrlName);
            return;
          }
          const { dataSheet, defaultCurrencyCode } = validateSheetMetaData(sheet);

          setSelectedCurrency(defaultCurrencyCode);
          setDataSheet(dataSheet);
          await formulaSheet.current.saveDataSheetOnCache(dataSheet);
          await formulaSheet.current.fromDataSheet(dataSheet);
          const updatedAggregations = await aggregationsProcessor.calculate(
            formulaSheet.current.getAllSheetValues(),
            formulaSheet.current.getL3FringesCellOrder(),
          );
          setAggregations(updatedAggregations);
          setIsDataSheetInitializing(false);
          setIsDataSheetFetchingFromRemote(false);
        })();
      }, 100);
    } catch (error) {
      handleOpenLoadingErrorDialog();
      setIsDataSheetInitializing(false);
      setIsDataSheetFetchingFromRemote(false);
      removeOpenedFilesFromStorage(fileUrlName);
      setFileUrlName(null);
      console.error('File not found or unable to load the file', error);
    }

    setIsNewlySavedFile(false);
  }, [
    fileUrlName,
    setDataSheet,
    setIsDataSheetInitializing,
    setIsNewlySavedFile,
    username,
    setAggregations,
    aggregationsProcessor,
  ]);

  const loadDataSheet = useCallback(async () => {
    if (!fileUrlName) return;

    setIsDataSheetInitializing(true);
    setFileOpenStatus('Downloading....');
    addFileInstanceToStorage();
    const isDataSheetAvailableOnCache = await formulaSheet.current.isDataSheetAvailableOnCache();
    if (isDataSheetAvailableOnCache) {
      await loadDataSheetIfAvailableOnCache();
    } else {
      await loadDataSheetIfNotAvailableOnCache();
    }
    setCurrentTabIndex(TabIndexes.BUDGET);
    navigate(APP_PATH.budget, { replace: true });
    clearDateSheetCache();
  }, [
    clearDateSheetCache,
    loadDataSheetIfAvailableOnCache,
    loadDataSheetIfNotAvailableOnCache,
    navigate,
    setCurrentTabIndex,
    setIsDataSheetInitializing,
    addFileInstanceToStorage,
  ]);

  const handleDialogYesOption = async () => {
    logout();
    resetCostTrackerOptions();
    setOpenLogout(false);
    globalSearchService.clearAllResults();
    globalSearchService.clearSearchQuery();
    await formulaSheet.current.clearDataSheetOnCache();
    setTimeout(() => {
      localStorage.clear();
      window.location.reload();
    }, 1000);
  };

  function handleDialogNoOption() {
    setOpenLogout(false);
  }

  const handleSaveAsFile = (isOpen: boolean, action?: string) => {
    setSaveAsOpen(isOpen);
    const debouncedTriggerSnackBar = lodash.debounce(triggerSnackBar, 100);
    if (action) {
      debouncedTriggerSnackBar(action);
    }
  };

  const handleOpenLoadingErrorDialog = () => {
    openDialog({
      message: 'Unable to load the selected sheet!',
      title: 'Error',
      onClickOk: () => {
        navigate(APP_PATH.welcome, { replace: true });
      },
      isYesNoOptionAllowed: false,
    });
  };

  const handleCloseBudget = async () => {
    if (!fileUrlName) return;

    // Clear cached data
    clearDateSheetCache();
    removeOpenedFilesFromStorage(fileUrlName);
    resetCostTrackerOptions();
    await formulaSheet.current.clearDataSheetOnCache();
    localStorage.removeItem(LocalStorageVariables.DPF_API_ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageVariables.FILE_NAME);

    // Update UI state
    navigate(APP_PATH.welcome, { replace: true });
    setFileUrlName(null);
    setDataSheet(null);
    setAggregations(null);
    setSideBarToggle({
      left: false,
      right: true,
    });
  };

  const handleMenuOptions = () => {
    const menuActions: Record<string, () => void> = {
      open: () => setOpenFileOpen(true),
      logout: () => setOpenLogout(true),
      close: () => handleCloseBudget(),
    };

    const action =
      menuActions[handleDataSheetChanges.menuOptions] ??
      (() => {
        throw new Error('Unknown menu option');
      });
    action();
  };

  const handleSaveDataSheetChangesPopupYes = () => {
    (async () => {
      if (!(await formulaSheet.current.isDataSheetAvailableOnCache())) {
        handleMenuOptions();
        return;
      }

      handleSaveChangesClose();
      await handleDataSheetSave('manual');
      handleMenuOptions();
    })();
  };

  const handleSaveDataSheetChangesPopupNo = () => {
    (async () => {
      if (!username || !fileUrlName) {
        return;
      }
      const isDataSheetAvailableOnCache = await formulaSheet.current.isDataSheetAvailableOnCache();
      if (!isDataSheetAvailableOnCache) {
        let formatFileName = removedExtensionsFilename(fileUrlName);
        formatFileName = formatFileName.concat(`.${DATA_SHEET_FILE_TYPES.TEMP}`);
        await DataSheetApiService.deleteFileByFilename(username, formatFileName);
      }
      handleSaveChangesClose();
      handleMenuOptions();
    })();
  };

  const tabs = getTabs(costTrackerMode);

  const handleTabChange = (newTabIndex: number) => {
    setCurrentTabIndex(newTabIndex);

    setPageTitleDetails({
      accountNumber: '',
      fileName: '',
      fullDescription: '',
      selectedSheetName: tabs[newTabIndex].topBarTitle,
    });
  };

  const handleSaveChangesOpen = (open: boolean) => {
    setHandleDataSheetChanges({ ...handleDataSheetChanges, isDataSheetChangesAvailable: open });
  };

  const handleSaveChangesClose = () => {
    setHandleDataSheetChanges({ ...handleDataSheetChanges, isDataSheetChangesAvailable: false });
  };

  const handleImport = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    files: IFileResponse[],
  ): Promise<{
    success: boolean;
    message: string;
  }> => {
    if (!dataSheet)
      return {
        success: false,
        message: 'Datasheet is not initialized',
      };
    await handleDataSheetSave('manual');

    try {
      const untitledImportFileCount = files.filter((file: { filename: string }) =>
        file.filename.startsWith('Untitled'),
      ).length;
      let untitledFileName =
        untitledImportFileCount > 0 ? `Untitled (${untitledImportFileCount})` : 'Untitled';
      untitledFileName += '.json';
      setSelectedSheet(initialSelectedSheet);

      if (formulaSheet.current && data) {
        const { dataSheet, defaultCurrencyCode } = validateSheetMetaData(data);
        await formulaSheet.current.fromDataSheet(data);
        setDataSheet(dataSheet);
        const currencyImport: ICurrencyImport | undefined = dataSheet.meta.currencyImport;
        if (currencyImport === undefined) {
          setSelectedCurrency(defaultCurrencyCode);
          await formulaSheet.current.updateBaseCurrency(defaultCurrencyCode);
        } else {
          if (currencyImport?.baseCurrency && currencyImport?.baseCurrency !== '') {
            setSelectedCurrency(currencyImport?.baseCurrency);
            await formulaSheet.current.updateBaseCurrency(currencyImport?.baseCurrency);
          } else {
            setSelectedCurrency(SYSTEM_CURRENCY_LABEL);
            await formulaSheet.current.updateBaseCurrency(SYSTEM_CURRENCY_LABEL);
          }
          const selectedCurrency =
            defaultCurrencyCode === SYSTEM_CURRENCY_LABEL && currencyImport.convCurrency
              ? currencyImport.convCurrency
              : defaultCurrencyCode;
          setSelectedCurrency(selectedCurrency);
        }

        await formulaSheet.current.saveDataSheetOnCache(dataSheet);
        const newAggregations = await aggregationProcessor.calculate(
          formulaSheet.current.getAllSheetValues(),
          formulaSheet.current.getL3FringesCellOrder(),
        );
        setAggregations(newAggregations);
        setIsNewlySavedFile(true);
        setFileUrlName(untitledFileName);

        return {
          success: true,
          message: 'Datasheet imported successfully',
        };
      } else {
        throw new Error('formulaSheet or data is null');
      }
    } catch (error) {
      logger.error('[handleImport] An error occured: ', error);
      if (error instanceof Error) {
        return {
          success: false,
          message: error.message,
        };
      }
      return {
        success: false,
        message: 'An unknown error occurred',
      };
    }
  };

  const outletContext: OutletContext = {
    formulaSheet: formulaSheet.current,
    // /* Temporarily disabled */ undoRedoService,
    useResetVariance,
    setUseResetVariance,
    setOpenReport,
    onLogout: handleLogout,
  };

  if (isLoading || isDataSheetInitializing) {
    return (
      <SuspenseSpinner>
        <Typography variant="body2" component="p">
          {fileOpenStatus}
        </Typography>
      </SuspenseSpinner>
    );
  }

  return (
    <div>
      <Grid container style={{ flexWrap: 'nowrap' }}>
        {sideBarShow.left ? (
          <Grid item style={{ width: 'max-content' }}>
            <Menu menuOptions={menuOptions} />
          </Grid>
        ) : null}

        <Grid item style={{ flex: 1, width: '100%' }}>
          <Tabs tabs={tabs} onTabChange={handleTabChange} />

          <SheetContainer>
            <Outlet context={outletContext} />
          </SheetContainer>
        </Grid>

        {/* Dialogs */}
        {saveAsOpen && (
          <Suspense fallback={<FileProgressSpinner />}>
            <SaveAsDialog
              open={saveAsOpen}
              onClose={handleSaveAsFile}
              suggestedNewFileName={newFileNameUrl}
            />
          </Suspense>
        )}
        <Suspense fallback={<FileProgressSpinner />}>
          <OpenFileDialog
            open={openFileOpen}
            setOpen={setOpenFileOpen}
            formulaSheet={formulaSheet.current}
            onSaveAsDialogOpen={(newFileNameUrl) => {
              setSaveAsOpen(true);
              setNewFileNameUrl(newFileNameUrl);
            }}
          />
        </Suspense>
        {openLogout && (
          <Suspense fallback={<FileProgressSpinner />}>
            <ConfirmationDialog
              open={openLogout}
              onOpen={setOpenLogout}
              title={'Logout'}
              message={'Are you sure you want to logout?'}
              onClickYes={handleDialogYesOption}
              onClickNo={handleDialogNoOption}
              isYesNoOptionAllowed={true}
            />
          </Suspense>
        )}
        {openShareDialog && (
          <Suspense fallback={<FileProgressSpinner />}>
            <ShareDialogBox open={openShareDialog} setOpen={setOpenShareDialog} />
          </Suspense>
        )}
        {openAccount && (
          <Suspense fallback={<FileProgressSpinner />}>
            <AccountDialogBox open={openAccount} setOpen={setOpenAccount} />
          </Suspense>
        )}
        {openReport && (
          <Suspense fallback={<FileProgressSpinner />}>
            <ReportDialogBox
              open={openReport}
              setOpen={setOpenReport}
              formulaSheet={formulaSheet.current}
            />
          </Suspense>
        )}
        {openSystemList && (
          <Suspense fallback={<FileProgressSpinner />}>
            <SystemListDialog open={openSystemList} setOpen={setOpenSystemList} />
          </Suspense>
        )}
        {openBudgetOption && (
          <Suspense fallback={<FileProgressSpinner />}>
            <BudgetOptionDialog
              open={openBudgetOption}
              setOpen={setOpenBudgetOption}
              formulaSheet={formulaSheet.current}
            />
          </Suspense>
        )}
        {openBudgetOption && (
          <Suspense fallback={<FileProgressSpinner />}>
            <BudgetOptionDialog
              open={openBudgetOption}
              setOpen={setOpenBudgetOption}
              formulaSheet={formulaSheet.current}
            />
          </Suspense>
        )}
        {openCostTrackerOption && (
          <Suspense fallback={<FileProgressSpinner />}>
            <CostTrackerOptionDialog
              open={openCostTrackerOption}
              setOpen={setOpenCostTrackerOption}
            />
          </Suspense>
        )}
        {openExport && (
          <Suspense fallback={<FileProgressSpinner />}>
            <ExportDialog
              open={openExport}
              setOpen={setOpenExport}
              formulaSheet={formulaSheet.current}
            />
          </Suspense>
        )}
        {openImport && (
          <Suspense fallback={<FileProgressSpinner />}>
            <ImportDialog
              open={openImport}
              setOpen={setOpenImport}
              formulaSheet={formulaSheet.current}
              onImport={handleImport}
              onDataSheetSave={handleDataSheetSave}
            />
          </Suspense>
        )}
        <Suspense fallback={<FileProgressSpinner />}>
          <ConfirmationDialog {...confirmationDialogProps} />
        </Suspense>
        {/* Data sheet changes Confirmation */}
        <Suspense fallback={<FileProgressSpinner />}>
          <ConfirmationDialog
            open={handleDataSheetChanges.isDataSheetChangesAvailable}
            onOpen={handleSaveChangesOpen}
            message={'Do you want to save those changes?'}
            title={'Unsaved Changes Detected'}
            isYesNoOptionAllowed={true}
            onClickYes={handleSaveDataSheetChangesPopupYes}
            onClickNo={handleSaveDataSheetChangesPopupNo}
          />
        </Suspense>
        <Suspense fallback={<FileProgressSpinner />}>
          <ConfirmationDialog
            open={confirmResetVariance}
            onOpen={setConfirmResetVariance}
            message={'Are you sure you want to reset the variance?'}
            title={'Reset Variance'}
            isYesNoOptionAllowed={true}
            onClickYes={() => {
              setUseResetVariance(true);
              setConfirmResetVariance(false);
            }}
            onClickNo={() => {
              setConfirmResetVariance(false);
              setUseResetVariance(false);
            }}
          />
        </Suspense>
        <FullScreenSpinner isLoading={fileSaveLoading} message="Saving..." />
      </Grid>
    </div>
  );
};

export default AppLayout;
export type { OutletContext };
