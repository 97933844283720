import { RowTypes } from '@/enums';
import { generatedId, UniqueObjectSet } from '@/utils';
import type { ICurrencyData } from '@/interfaces/ICurrencyData';
import type { IFringeData } from '@/interfaces/IFringeData';
import type { IGlobalData } from '@/interfaces/IGlobalData';
import type { IGroupData } from '@/interfaces/IGroupData';
import type { ILocationData } from '@/interfaces/ILocationData';
import type { ISetData } from '@/interfaces/ISetData';
import type { IUnitDescriptionData } from '@/interfaces/IUnitDescriptionData';

class FileImporterHelper {
  public createCurrencies(
    currencySet: UniqueObjectSet<{ code: string; value: number }, 'code'>,
    baseCurrency: string,
  ) {
    const uniqueCurrencies = currencySet.values();
    const baseCurrencyIndex = uniqueCurrencies.findIndex(({ code }) => code === baseCurrency);

    if (baseCurrencyIndex === -1) {
      uniqueCurrencies.push({ code: baseCurrency, value: 1 });
    }

    return uniqueCurrencies.map(({ code, value }) => {
      const currency = this.getEmptyCurrency();
      currency.code = code;
      currency.rate = value.toString();
      currency.default = baseCurrency === code ? 'Y' : 'N';
      return currency;
    });
  }

  public createUnitDescriptions(
    unitDescSet: UniqueObjectSet<{ code: string; value: number }, 'code'>,
  ) {
    const uniqueUnitDesc = unitDescSet.values();

    return uniqueUnitDesc.map(({ code, value }) => {
      const unitDesc = this.getEmptyUnitDesc();
      unitDesc.code = code;
      unitDesc.description = code;
      unitDesc.units = value.toString();
      return unitDesc;
    });
  }

  public createSets(sets: UniqueObjectSet<{ code: string }, 'code'>) {
    const uniqueSets = sets.values();

    return uniqueSets.map(({ code }) => {
      const newSet = this.getEmptySet();
      newSet.code = code;
      return newSet;
    });
  }

  public createLocations(locationsSet: UniqueObjectSet<{ code: string }, 'code'>) {
    const uniqueLocations = locationsSet.values();

    return uniqueLocations.map(({ code }) => {
      const location = this.getEmptyLocation();
      location.code = code;
      return location;
    });
  }

  public createGroups(groupsSet: UniqueObjectSet<{ code: string }, 'code'>) {
    const uniqueGroups = groupsSet.values();

    return uniqueGroups.map(({ code }) => {
      const group = this.getEmptyGroup();
      group.code = code;
      return group;
    });
  }

  public createGlobals(globalsSet: UniqueObjectSet<{ code: string; value: number }, 'code'>) {
    const uniqueGlobals = globalsSet.values();

    return uniqueGlobals.map(({ code, value }) => {
      const global = this.getEmptyGlobal();
      global.code = code;
      global.units = value.toString();
      return global;
    });
  }

  public createFringes(
    fringesSet: UniqueObjectSet<{ code: string; unit: string; value: number }, 'code'>,
  ) {
    const uniqueFringes = fringesSet.values();

    return uniqueFringes.map(({ code, unit, value }) => {
      const fringe = this.getEmptyFringe();
      fringe.code = code;
      fringe.unitDesc = unit;
      fringe.rate = value.toString();
      return fringe;
    });
  }

  public getLevel2EmptyTotalFringeRow() {
    return {
      id: generatedId(),
      account: '',
      description: 'Total Fringes',
      rowType: RowTypes.F,
    };
  }

  public getLevel2EmptyTotalRow() {
    return {
      id: generatedId(),
      account: ' ',
      description: '',
      rowType: RowTypes.T,
    };
  }

  public getLevel3EmptyTotalFringeRow() {
    return {
      id: generatedId(),
      description: 'Total Fringes',
      range: '',
      fringe: '',
      fringes: '',
      groups: '',
      loc: '',
      set: '',
      units: '',
      desc: '',
      x: '',
      rate: '',
      cu: '',
      comparison: 0,
      rowType: RowTypes.F,
      fringeComparison: '',
      comparisonCurrency: '',
    };
  }

  public getLevel3EmptyTotalRow() {
    return {
      id: generatedId(),
      description: '',
      range: '',
      fringe: '',
      fringes: '',
      groups: '',
      loc: '',
      set: '',
      units: '',
      desc: '',
      x: '',
      rate: '',
      cu: '',
      comparison: '',
      rowType: RowTypes.T,
      fringeComparison: '',
    };
  }

  private getEmptyCurrency(): ICurrencyData {
    return {
      id: generatedId(),
      category: 'Currency',
      code: '',
      description: '',
      rate: '1',
      default: 'N',
      includeFringe: 'N',
      total: '0',
      usage: '0',
      attachments: '0',
      notes: '',
    };
  }

  private getEmptyUnitDesc(): IUnitDescriptionData {
    return {
      id: generatedId(),
      category: 'Unit Desc',
      code: '',
      description: '',
      units: '0',
      usage: '0',
      attachments: '0',
      notes: '',
    };
  }

  private getEmptySet(): ISetData {
    return {
      id: generatedId(),
      category: 'Sets',
      code: '',
      description: 'Set Code',
      includeFringe: 'Y',
      total: '0',
      usage: '0',
      attachments: '0',
      notes: '',
    };
  }

  private getEmptyLocation(): ILocationData {
    return {
      id: generatedId(),
      category: 'Locations',
      code: '',
      description: '',
      includeFringe: 'N',
      total: '0',
      usage: '0',
      attachments: '0',
      notes: '',
    };
  }

  private getEmptyGroup(): IGroupData {
    return {
      id: generatedId(),
      category: 'Groups',
      code: '',
      description: '',
      suppress: 'N',
      cap: '0',
      cu: '',
      color: '',
      includeFringe: 'Y',
      total: '0',
      usage: '0',
      attachments: '0',
      notes: '',
    };
  }

  private getEmptyGlobal(): IGlobalData {
    return {
      id: generatedId(),
      category: 'Globals',
      code: '',
      description: '',
      units: '0',
      usage: '0',
      attachments: '0',
      notes: '',
      calculation: '0',
    };
  }

  private getEmptyFringe(): IFringeData {
    return {
      id: generatedId(),
      category: 'Fringes',
      code: '',
      description: '',
      rate: '0',
      unitDesc: '%',
      cap: '0',
      cu: '',
      total: '0',
      usage: '0',
      attachments: '0',
      notes: '',
    };
  }
}

export default FileImporterHelper;
