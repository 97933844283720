import React from 'react';
import { parseString } from 'xml2js';
import { RowTypes } from '@/enums';
import { createInitialImportDataSheet, expectedKeys, formatValue, generatedId } from '@/utils';
import {
  getL2SystemTotalFringesRow,
  getL2SystemTotalRow,
  getL3SystemTotalFringesRow,
  getL3SystemTotalRow,
} from '@/helpers/DataSheetHelper';
import {
  addEmptyRows,
  buildConfig,
  buildL2RowFromImportSheet,
  buildMetaData,
  createCurrencyObjects,
  createFringesObjects,
  createGroupsObjects,
  createLocationsObjects,
  createSetsObjects,
  createUnitDescObjects,
  emptyRowsTypes,
  getBaseCurrencyImport,
} from '@/helpers/Mappers/SheetImportMapper';
import { jsonFilteredImportDataWithNewIds } from '@/helpers/SheetImportJSONHelper';
import { xmlFilteredImportDataWithNewIds } from '@/helpers/SheetImportXMLHelper';
import {
  DateSheetAttributeType,
  IDataSheet,
  IL1Data,
  IL2Data,
  IL3Data,
} from '@/interfaces/IDataSheet';
import {
  IAccountImport,
  ICategoryImport,
  IDetailImport,
  IL2DataImport,
  IL3DataImport,
} from '@/interfaces/ISheetImport';

type ErrorMessage = string | null | undefined;

export function buildDataSheetFromJSONFile(
  jsonString: string,
  setInvalidFormat: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>,
  setImportData: React.Dispatch<React.SetStateAction<IDataSheet>>,
) {
  try {
    const jsonData = JSON.parse(jsonString);
    const isValid = expectedKeys.every((key) => key in (jsonData.budget || {}));

    if (isValid) {
      setInvalidFormat(false);
      const jsonImportNewData = jsonFilteredImportDataWithNewIds(jsonData);
      setImportData(jsonImportNewData);
    } else {
      setInvalidFormat(true);
    }
  } catch (error) {
    console.error('Error parsing JSON:', error);
    setErrorMessage('Error parsing JSON');
  }
}

export function buildDataSheetFromXmlFile(
  xmlString: string,
  setUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>,
  setImportData: React.Dispatch<React.SetStateAction<IDataSheet>>,
) {
  parseString(xmlString, { explicitArray: false }, (err, result) => {
    if (err) {
      console.error('Error parsing XML:', err);
      setErrorMessage('Error parsing XML');
      setUploadSuccess(false);
    } else {
      const isValidKeys = expectedKeys.every((key) => key in (result.budget || {}));
      if (isValidKeys) {
        setErrorMessage(null);
        const importNewXML = xmlFilteredImportDataWithNewIds(result);
        setUploadSuccess(true);
        setImportData(importNewXML);
      } else {
        setErrorMessage('Invalid format');
        setUploadSuccess(false);
      }
    }
  });
}

export function buildDataSheetFromPivotSheet(
  jsonString: string,
  setUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null | undefined>>,
  setImportData: React.Dispatch<React.SetStateAction<IDataSheet>>,
) {
  try {
    const jsonData = JSON.parse(jsonString);
    if (isValidDataSheet(jsonData)) {
      setUploadSuccess(true);
      setImportData(jsonData);
    } else {
      setErrorMessage('Invalid format');
      setUploadSuccess(false);
    }
  } catch (error) {
    console.error('Error parsing Hollywood Budgets sheet:', error);
    setErrorMessage('Error parsing Hollywood Budgets sheet');
    setUploadSuccess(false);
  }

  function isValidDataSheet(data: IDataSheet): data is IDataSheet {
    return Object.values(DateSheetAttributeType)
      .filter((key) => key !== DateSheetAttributeType.FILE_ATTACHMENTS)
      .every((key) => key in data);
  }
}

export const buildL1FromImportSheet = (
  categories: ICategoryImport[],
  dataSheet: IDataSheet,
): void => {
  for (const category of categories) {
    const l1Object: IL1Data = {
      id: category.cID,
      account: category.cNumber,
      description: category.cDescription,
      rowType: category.cRowType ?? RowTypes.D,
    };
    dataSheet.l1.push(l1Object);
  }

  const isFringeRowAvailableInL1 = dataSheet.l1.some((obj) => obj.rowType === RowTypes.F);

  if (!isFringeRowAvailableInL1) {
    const totalRowIndex = dataSheet.l1.findIndex((obj) => obj.rowType === RowTypes.T);
    const l1TotalFringesRow: IL1Data = {
      id: generatedId(),
      account: ' ',
      description: 'Total Fringes',
      rowType: RowTypes.F,
    };
    if (totalRowIndex !== -1) {
      dataSheet.l1.splice(totalRowIndex, 0, l1TotalFringesRow);
    } else {
      dataSheet.l1.push(l1TotalFringesRow);
    }
  }

  const isTotalRowAvailableInL1 = dataSheet.l1.some((obj) => obj.rowType === RowTypes.T);

  if (!isTotalRowAvailableInL1) {
    const l1SystemTotalRow: IL1Data = {
      id: generatedId(),
      account: ' ',
      description: 'Total',
      rowType: RowTypes.T,
    };

    dataSheet.l1.push(l1SystemTotalRow);
  }
};

const updateL2RowFromImportSheet = (
  l2Object: IL2DataImport,
  l1Object: IL1Data,
  dataSheet: IDataSheet,
): void => {
  const l2Array = dataSheet.l2[l1Object.id];
  if (!l2Array) {
    dataSheet.l2[l1Object.id] = [l2Object];
    dataSheet.sheetNames.l2.push(l1Object.id as string);
  } else {
    l2Array.push(l2Object);
    if (dataSheet.l3[l2Object.id]) {
      dataSheet.l3[l2Object.id] = [];
    }
  }
};

export const buildL2FromImportSheet = (accounts: IAccountImport[], dataSheet: IDataSheet): void => {
  for (const account of accounts) {
    const l2Object = buildL2RowFromImportSheet(account);
    const l1Object = dataSheet.l1.find(
      (l1: { id: string | number }) => l1.id === l2Object.categoryId,
    );
    dataSheet.sheetNames.l3.push(String(l2Object.id));

    if (l1Object) {
      updateL2RowFromImportSheet(l2Object, l1Object, dataSheet);
    }
  }

  for (const l2Array of Object.values<IL2Data[]>(dataSheet.l2)) {
    const isFringeRowAvailableL2 = l2Array.some((obj) => obj.rowType === RowTypes.F);

    if (!isFringeRowAvailableL2) {
      const totalRowIndex = l2Array.findIndex((obj) => obj.rowType === RowTypes.T);
      const l2TotalFringesRow = getL2SystemTotalFringesRow();
      if (totalRowIndex !== -1) {
        l2Array.splice(totalRowIndex, 0, l2TotalFringesRow);
      } else {
        l2Array.push(l2TotalFringesRow);
      }
    }
  }

  for (const l2Array of Object.values<IL2Data[]>(dataSheet.l2)) {
    const isTotalRowAvailableL2 = l2Array.some((obj) => obj.rowType === RowTypes.T);

    if (!isTotalRowAvailableL2) {
      const l2SystemTotalRow = getL2SystemTotalRow();
      l2Array.push(l2SystemTotalRow);
    }
  }
};

export const buildMetaDataFrom = (dataSheet: IDataSheet, meta: any) => {
  dataSheet.meta = buildMetaData(meta);
  return dataSheet;
};

export const buildConfigImport = (dataSheet: IDataSheet, config: any) => {
  dataSheet.configs = buildConfig(config);
  return dataSheet;
};

export const buildMetaAndConfigs = (
  buildFunction: (dataSheet: IDataSheet, meta: any) => void,
  meta: any,
  dataSheet: IDataSheet,
) => {
  buildFunction(dataSheet, meta);
};

export const buildImportDataSheet = (
  data: any,
  addL1: any,
  addL2: any,
  addL3: any,
  addMetaData?: any,
  addConfigData?: any,
): IDataSheet => {
  const dataSheet: IDataSheet = createInitialImportDataSheet();

  if (data.budget?.categories?.category) {
    const categories: ICategoryImport = Array.isArray(data.budget.categories.category)
      ? data.budget.categories.category
      : [data.budget.categories.category];
    addL1(categories, dataSheet);
  }

  if (data.budget?.accounts?.account) {
    const accounts: IAccountImport = Array.isArray(data.budget.accounts.account)
      ? data.budget.accounts.account
      : [data.budget.accounts.account];
    addL2(accounts, dataSheet);
  }

  if (data.budget?.details?.detail && data.budget?.metadata) {
    const details: IDetailImport = Array.isArray(data.budget.details.detail)
      ? data.budget.details.detail
      : [data.budget.details.detail];
    const metaData: any = Array.isArray(data.budget.metadata)
      ? data.budget.metadata
      : [data.budget.metadata];
    addL3(details, dataSheet, metaData);
  }
  if (typeof addMetaData === 'function' && data.budget?.metadata) {
    const metaData: any = Array.isArray(data.budget.metadata)
      ? data.budget.metadata
      : [data.budget.metadata];
    addMetaData(metaData, dataSheet);
    addConfigData(metaData, dataSheet);
  }
  if (typeof addConfigData === 'function' && data.budget?.metadata) {
    const metaData: any = Array.isArray(data.budget.metadata)
      ? data.budget.metadata
      : [data.budget.metadata];
    addConfigData(metaData, dataSheet);
  }

  addEmptyRows(dataSheet, emptyRowsTypes);

  return dataSheet;
};

export const addSystemRowImportSheet = (dataSheet: IDataSheet) => {
  for (const l3Array of Object.values<IL3Data[]>(dataSheet.l3)) {
    const isFringeRowAvailableL3 = l3Array.some((obj) => obj.rowType === RowTypes.F);
    if (!isFringeRowAvailableL3) {
      const totalRowIndex = l3Array.findIndex((obj) => obj.rowType === RowTypes.T);
      const fRow = getL3SystemTotalFringesRow();
      if (totalRowIndex !== -1) {
        l3Array.splice(totalRowIndex, 0, fRow);
      } else {
        l3Array.push(fRow);
      }
    }

    for (const l3Array of Object.values<IL3Data[]>(dataSheet.l3)) {
      const isTotalRowAvailableL3 = l3Array.some((obj) => obj.rowType === RowTypes.T);
      if (!isTotalRowAvailableL3) {
        const tRow = getL3SystemTotalRow();
        l3Array.push(tRow);
      }
    }
  }
};

export const createFringeAndGroupDetails = (detail: any) => {
  let fringeDetails = [];
  let groupDetails = [];

  if (Array.isArray(detail.dFringes?.dFringe)) {
    fringeDetails = detail.dFringes.dFringe;
  } else if (typeof detail.dFringes?.dFringe === 'string') {
    fringeDetails = [detail.dFringes.dFringe];
  }

  if (Array.isArray(detail.dGroups?.dGroup)) {
    groupDetails = detail.dGroups.dGroup;
  } else if (typeof detail.dGroups?.dGroup === 'string') {
    groupDetails = [detail.dGroups.dGroup];
  }

  return {
    fringeDetails,
    groupDetails,
  };
};

export const updateL3RowFromImportSheet = (
  dataSheet: IDataSheet,
  l3Object: IL3DataImport,
): void => {
  for (const l2Id of Object.keys(dataSheet.l2)) {
    const l2Array = dataSheet.l2[l2Id];
    const matchingL2Object = l2Array.find(
      (l2Item: { id: string | number }) => l2Item.id === l3Object.accountId,
    );
    if (matchingL2Object) {
      if (!dataSheet.l3[l3Object.accountId]) {
        dataSheet.l3[l3Object.accountId] = [];
      }
      dataSheet.l3[l3Object.accountId].push(l3Object);
      break;
    }
  }
};

export const addToSets = (
  l3Object: IL3DataImport,
  currency: Set<string>,
  sets: Set<string>,
  locations: Set<string>,
  unitDesc: Set<string>,
  addToMasterAndSpecificSet: (value: string, specificSet: Set<string>) => void,
): void => {
  if (l3Object.cu !== '') {
    addToMasterAndSpecificSet(l3Object.cu, currency);
  }
  if (l3Object.set !== '') {
    addToMasterAndSpecificSet(l3Object.set, sets);
  }
  if (l3Object.loc !== '') {
    addToMasterAndSpecificSet(l3Object.loc, locations);
  }
  if (l3Object.desc !== '') {
    addToMasterAndSpecificSet(l3Object.desc, unitDesc);
  }
};

export const removeWhiteSpaces = (arr?: string[]): string => {
  return arr?.map((item) => item.trim()).join(',') ?? '';
};

function filterAndUpdateL1Rows(dataSheet: IDataSheet) {
  dataSheet.l1.forEach((l1Object) => {
    if (
      l1Object.rowType === RowTypes.D &&
      (!dataSheet.l2[l1Object.id] || dataSheet.l2[l1Object.id].length === 0)
    ) {
      // Create a new l2 row with type D and category ID as the ID of the l1
      const newL2Row = {
        id: generatedId(),
        account: '',
        description: '',
        rowType: 'D',
        categoryId: l1Object.id,
      };

      // Create additional rows as needed
      const l2SystemTotalFringesRow = getL2SystemTotalFringesRow();
      const l2SystemTotalRow = getL2SystemTotalRow();

      // Add the new l2 rows to the dataSheet in the specified order
      if (!dataSheet.l2[newL2Row.categoryId]) {
        dataSheet.l2[newL2Row.categoryId] = [];
      }
      dataSheet.l2[newL2Row.categoryId].push(newL2Row, l2SystemTotalFringesRow, l2SystemTotalRow);
    }
  });
}

function filterAndUpdateL2Rows(dataSheet: IDataSheet) {
  Object.values(dataSheet.l2).forEach((l2Array) => {
    l2Array.forEach((l2Object) => {
      if (
        l2Object.rowType === RowTypes.D &&
        (!dataSheet.l3[l2Object.id] || dataSheet.l3[l2Object.id].length === 0)
      ) {
        const newL3Row = {
          id: generatedId(),
          range: '',
          fringe: '',
          fringes: '',
          groups: '',
          loc: '',
          set: '',
          description: '',
          units: '',
          desc: '',
          x: '',
          rate: '',
          cu: '',
          comparison: 0,
          rowType: 'D',
          accountId: l2Object.id,
          fringeComparison: 0,
        };

        const l3SystemTotalFringesRow = getL3SystemTotalFringesRow();
        const l3SystemTotalRow = getL3SystemTotalRow();

        if (!dataSheet.l3[newL3Row.accountId]) {
          dataSheet.l3[newL3Row.accountId] = [];
        }
        dataSheet.l3[newL3Row.accountId].push(newL3Row, l3SystemTotalFringesRow, l3SystemTotalRow);
      }
    });
  });
}

export function updateImportDataWithNewIds(
  data: any,
  addL1: any,
  addL2: any,
  addL3: any,
  addMetaData?: any,
  addConfigData?: any,
): IDataSheet {
  const dataSheet = buildImportDataSheet(data, addL1, addL2, addL3, addMetaData, addConfigData);
  const l2SheetNames = new Set<string>();
  const l3SheetNames = new Set<string>();

  const l1IdMapping: { [oldId: string]: string } = {};
  dataSheet.l1 = dataSheet.l1.map((l1Object) => {
    const newId = generatedId();
    l1IdMapping[l1Object.id] = newId;
    return { ...l1Object, id: newId };
  });

  updateIdsAndSheetNames(dataSheet, l1IdMapping);
  filterAndUpdateL1Rows(dataSheet);
  filterAndUpdateL2Rows(dataSheet);
  for (const key of Object.keys(dataSheet.l2)) {
    l2SheetNames.add(key);
  }
  for (const key of Object.keys(dataSheet.l3)) {
    l3SheetNames.add(key);
  }
  dataSheet.sheetNames.l2 = Array.from(l2SheetNames);
  dataSheet.sheetNames.l3 = Array.from(l3SheetNames);

  return dataSheet;
}

function updateIdsAndSheetNames(dataSheet: IDataSheet, l1IdMapping: { [oldId: string]: string }) {
  const l2IdMapping: { [oldId: string]: string } = {};

  // Update l2 objects
  for (const l2Array of Object.values(dataSheet.l2)) {
    for (const l2Object of l2Array as any) {
      const oldCategoryIdL3 = l2Object.categoryId;
      const newCategoryIdL3 = l1IdMapping[l2Object.categoryId];
      delete dataSheet.l2[oldCategoryIdL3];
      if (newCategoryIdL3) {
        l2Object.categoryId = newCategoryIdL3;
        dataSheet.l2[l2Object.categoryId] = l2Array as any;
      }
      const newId = generatedId();
      l2IdMapping[l2Object.id] = newId;
      l2Object.id = newId;
    }
  }

  // Update l3 objects
  for (const l3Array of Object.values(dataSheet.l3)) {
    for (const l3Object of l3Array as any) {
      const oldAccountIdL3 = l3Object.accountId;
      const newAccountIdL3 = l2IdMapping[l3Object.accountId];
      delete dataSheet.l3[oldAccountIdL3];
      if (newAccountIdL3) {
        l3Object.accountId = newAccountIdL3;
        dataSheet.l3[l3Object.accountId] = l3Array as any;
      }
    }
  }
}

export const sanitizeCodeOnImport = (value: string, allowPercent = false): string =>
  namedExpressionsFormatter(value, allowPercent);

export const sanitizeCodeOnImportArray = (value: string[]) => namedExpressionsFormatter(value);

export const sanitizeFringesOnImport = (arr: string[]): string => {
  return removeWhiteSpaces(sanitizeCodeOnImportArray(arr));
};

export const namedExpressionsFormatter = (values: string | string[], allowPercent = false): any => {
  return Array.isArray(values)
    ? values.map((value) => formatValue(value, allowPercent))
    : formatValue(values, allowPercent);
};

export const initializeSet = () => {
  const masterSet: Set<string> = new Set();
  const currency: Set<string> = new Set();
  const sets: Set<string> = new Set();
  const locations: Set<string> = new Set();
  const unitDesc: Set<string> = new Set();
  const fringes: any = new Set();
  const groups: any = new Set();
  return { masterSet, currency, sets, locations, unitDesc, fringes, groups };
};

export const formatMasterAndSpecificSet = (
  value: string,
  specificSet: Set<string>,
  masterSet: Set<string>,
  seenLowerCase: Set<string>,
  applyFormatter: boolean,
) => {
  let processedValue;
  if (applyFormatter) {
    processedValue = namedExpressionsFormatter(value);
  } else {
    processedValue = value;
  }
  if (!processedValue) return;
  const lowerCaseValue = value?.toLowerCase?.() ?? '';
  if (seenLowerCase.has(lowerCaseValue)) return;
  seenLowerCase.add(lowerCaseValue);
  masterSet.add(processedValue);
  specificSet.add(processedValue);
};

export const updateDataSheet = (
  dataSheet: IDataSheet,
  currency: Set<string>,
  sets: Set<string>,
  locations: Set<string>,
  unitDesc: Set<string>,
  fringes: Set<string>,
  groups: Set<string>,
  meta?: any,
) => {
  dataSheet.currency = createCurrencyObjects(currency, getBaseCurrencyImport(meta));
  dataSheet.sets = createSetsObjects(sets);
  dataSheet.locations = createLocationsObjects(locations);
  dataSheet.unitDesc = createUnitDescObjects(unitDesc as Set<string>);
  dataSheet.fringes = createFringesObjects(fringes as Set<string>);
  dataSheet.groups = createGroupsObjects(groups as Set<string>);
};
