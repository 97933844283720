import { type IDataSheet } from '@/interfaces/IDataSheet';
import FileImporter from './importer.abstract';

// TODO: Implement import strategy for XML files, clean up previous implementation
class XMLStrategy extends FileImporter {
  parse(fileContent: string): unknown {
    return {};
  }

  validate(parsedData: unknown): boolean {
    return false;
  }

  mapToDataSheet(validatedData: unknown): IDataSheet {
    return {} as IDataSheet;
  }
}

export default XMLStrategy;
