import { findIndex } from 'lodash';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  BudgetLevels,
  L2aCostTrackerColumnIndexes,
  NavigationType,
  RowTypes,
  SheetNames,
  VisibleL1ColumnIndexes,
  VisibleL2ColumnIndexes,
  VisibleL3ColumnIndexes,
} from '@/enums';
import {
  currentL3FilterIndexAtom,
  dataSheetAtom,
  rawCostTrackerL2aData,
  selectedSheetIdsAtom,
} from '@/atoms/DataSheetAtom';
import {
  budgetSheetHighlightCellAtom,
  budgetSheetLevelAtom,
  pageTitleDetailsAtom,
} from '@/atoms/GlobalAtoms';
import { FringeAllocationDataKeys } from '@/data/meta/FringeAllocationData';
import { handleLevel1PageTitleDetails } from '@/helpers/L1Helpers';
import { handleLevel2PageTitleDetails } from '@/helpers/L2Helpers';
import { handleLevel3PageTitleDetails } from '@/helpers/L3Helpers';
import { IL1Data, IL2Data } from '@/interfaces/IDataSheet';
import { type ISelectedSheet } from '@/interfaces/ISelectedSheet';

interface NavigationResult {
  newSelectedSheet: ISelectedSheet;
  currentSheetLevel: BudgetLevels;
}

interface UseBudgetNavigationProps {
  costTrackerMode?: boolean;
  selectedIndexes?: number[];
  onNavigationSuccess?: ({ newSelectedSheet, currentSheetLevel }: NavigationResult) => void;
}

const getLevelConfig = (level: BudgetLevels) => {
  const config = {
    [BudgetLevels.SECOND_LEVEL]: {
      levelKey: 'l1' as SheetNames.L1,
      selectedIdKey: 'l1SheetId' as keyof ISelectedSheet,
    },
    [BudgetLevels.THIRD_LEVEL]: {
      levelKey: 'l2' as SheetNames.L2,
      selectedIdKey: 'l2SheetId' as keyof ISelectedSheet,
    },
  };

  if (!config[level as keyof typeof config]) {
    throw new Error('Invalid level');
  }

  return config[level as keyof typeof config];
};

const useBudgetNavigation = ({
  costTrackerMode,
  selectedIndexes,
  onNavigationSuccess,
}: UseBudgetNavigationProps) => {
  const selectedSheet = useRecoilValue(selectedSheetIdsAtom);
  const currentSheetLevel = useRecoilValue(budgetSheetLevelAtom);
  const rawCTL2aData = useRecoilValue(rawCostTrackerL2aData);
  const dataSheet = useRecoilValue(dataSheetAtom);
  const [currentL3FilterIndex, setCurrentL3FilterIndex] = useRecoilState(currentL3FilterIndexAtom);
  const setPageTitleDetails = useSetRecoilState(pageTitleDetailsAtom);
  const setHighlightCells = useSetRecoilState(budgetSheetHighlightCellAtom);

  const getDataByLevel = useCallback(
    (level: BudgetLevels): Array<IL1Data | IL2Data> => {
      if (!dataSheet || !selectedSheet) return [];
      const { levelKey } = getLevelConfig(level);
      const data =
        level === BudgetLevels.SECOND_LEVEL
          ? (dataSheet[levelKey] as Array<IL1Data> | undefined)
          : (dataSheet[levelKey as SheetNames.L2]?.[selectedSheet.l1SheetId] as
              | Array<IL2Data>
              | undefined);

      return (data || []).filter((row) => row.rowType === RowTypes.D);
    },
    [dataSheet, selectedSheet],
  );

  const handleL1Navigation = (id: string) => {
    setHighlightCells({
      startRow: 0,
      endRow: 0,
      startCol: costTrackerMode
        ? L2aCostTrackerColumnIndexes.account
        : VisibleL2ColumnIndexes.account,
      endCol: costTrackerMode
        ? L2aCostTrackerColumnIndexes.account
        : VisibleL2ColumnIndexes.account,
    });

    onNavigationSuccess?.({
      newSelectedSheet: { ...selectedSheet, l1SheetId: id },
      currentSheetLevel: BudgetLevels.SECOND_LEVEL,
    });
  };

  const handleL2Navigation = (id: string) => {
    setHighlightCells({
      startRow: 0,
      endRow: 0,
      startCol: VisibleL3ColumnIndexes.description,
      endCol: VisibleL3ColumnIndexes.description,
    });

    onNavigationSuccess?.({
      newSelectedSheet: { ...selectedSheet, l2SheetId: id },
      currentSheetLevel: BudgetLevels.THIRD_LEVEL,
    });
  };

  const handleL3Navigation = (navType: NavigationType) => {
    if (!dataSheet || !selectedSheet) return;
    const allRowsInL1 = getDataByLevel(BudgetLevels.SECOND_LEVEL);
    const currentL1SheetIndex = allRowsInL1.findIndex(({ id }) => id === selectedSheet.l1SheetId);
    const nextL1SheetIndex = currentL1SheetIndex + (navType === NavigationType.NEXT ? 1 : -1);

    if (!allRowsInL1[nextL1SheetIndex]) return;

    const nextL2SheetId = allRowsInL1[nextL1SheetIndex].id.toString();
    const allRowsInNextL2 = dataSheet.l2[nextL2SheetId].filter((row) => row.rowType === RowTypes.D);
    const nextL3SheetId =
      allRowsInNextL2[
        navType === NavigationType.NEXT ? 0 : allRowsInNextL2.length - 1
      ]?.id.toString();

    if (!nextL3SheetId) return;

    onNavigationSuccess?.({
      newSelectedSheet: {
        l1SheetId: nextL2SheetId,
        l2SheetId: nextL3SheetId,
        l3SheetId: '',
      },
      currentSheetLevel: BudgetLevels.THIRD_LEVEL,
    });
  };

  const findRowIndexByAccountId = (
    accountId: string,
    level: SheetNames.L1 | SheetNames.L2,
    parentId?: string,
  ): number => {
    if (!dataSheet) return 0;

    const getRows = () => {
      if (level === SheetNames.L1) return dataSheet.l1;
      if (!parentId) return [];
      return dataSheet.l2[parentId];
    };

    let rows = getRows();

    if (!['production'].includes(dataSheet.meta.fringes.calc)) {
      rows = rows.filter((row) => row.rowType !== RowTypes.FS);
    }

    return rows.findIndex((val) => val.id === accountId) ?? 0;
  };

  const getSheetIndex = (level: BudgetLevels, sheetId: string): number => {
    const allRows = getDataByLevel(level);
    return allRows.findIndex(({ id }) => id === sheetId);
  };

  const isLevelNavigationDisabled = (
    level: BudgetLevels,
    sheetId: string,
    direction: NavigationType,
  ): boolean => {
    const allRows = getDataByLevel(level);
    const sheetIndex = getSheetIndex(level, sheetId);
    return direction === NavigationType.PREVIOUS
      ? sheetIndex - 1 < 0
      : sheetIndex + 1 >= allRows.length;
  };

  const handleCostTrackerNavigation = (navType: NavigationType) => {
    const currentSheetIndex = findIndex(
      rawCTL2aData,
      (l2aData) => l2aData[L2aCostTrackerColumnIndexes.index + 1] === currentL3FilterIndex,
    );

    if (currentSheetIndex === -1) return;

    const nextSheetIndex =
      navType === NavigationType.NEXT
        ? rawCTL2aData[currentSheetIndex + 1][L2aCostTrackerColumnIndexes.index + 1]
        : rawCTL2aData[currentSheetIndex - 1]?.[L2aCostTrackerColumnIndexes.index + 1] ?? 0;

    if (typeof nextSheetIndex !== 'number') return;

    // Determine number of system rows to skip: if fringes are posted by category, skip fringe and total rows, otherwise skip only the total row
    const systemRowsCount =
      dataSheet?.meta?.fringes?.calc === FringeAllocationDataKeys.CATEGORY ? 2 : 1;

    // Check if the next sheet index is at the start or beyond the valid data range after accounting for system rows
    if (nextSheetIndex === 0 || nextSheetIndex > rawCTL2aData.length - systemRowsCount) {
      const { selectedIdKey } = getLevelConfig(BudgetLevels.SECOND_LEVEL);
      const allRowsInCurrentLevel = getDataByLevel(BudgetLevels.SECOND_LEVEL);
      const currentIndex =
        allRowsInCurrentLevel?.findIndex(({ id }) => id === selectedSheet[selectedIdKey]) ?? -1;

      const nextRowIndex = currentIndex + (navType === NavigationType.NEXT ? 1 : -1);
      const nextRowId = allRowsInCurrentLevel[nextRowIndex]?.id.toString();

      onNavigationSuccess?.({
        newSelectedSheet: {
          ...selectedSheet,
          [selectedIdKey]: nextRowId,
        },
        currentSheetLevel: BudgetLevels.SECOND_LEVEL,
      });
      setCurrentL3FilterIndex?.(1);
      setHighlightCells({ startRow: -1, endRow: -1, startCol: -1, endCol: -1 });
    } else {
      const nextSheetId = rawCTL2aData[nextSheetIndex - 1][0] as string;
      setCurrentL3FilterIndex?.(nextSheetIndex);
      onNavigationSuccess?.({
        newSelectedSheet: {
          ...selectedSheet,
          [getLevelConfig(BudgetLevels.THIRD_LEVEL).selectedIdKey]: nextSheetId,
        },
        currentSheetLevel: BudgetLevels.THIRD_LEVEL,
      });
    }
  };

  const handleClickDrillUp = () => {
    if (!dataSheet) return;

    if (currentSheetLevel === BudgetLevels.SECOND_LEVEL && dataSheet) {
      const rowIndex = findRowIndexByAccountId(selectedSheet.l1SheetId, SheetNames.L1);

      const prevSelectTopSheetTitleDetails = handleLevel1PageTitleDetails(
        dataSheet.meta?.file.projectName ?? '',
      );
      setPageTitleDetails(prevSelectTopSheetTitleDetails);
      setHighlightCells({
        startRow: rowIndex,
        endRow: rowIndex,
        startCol: VisibleL1ColumnIndexes.account,
        endCol: VisibleL1ColumnIndexes.account,
      });

      onNavigationSuccess?.({
        newSelectedSheet: { ...selectedSheet, l1SheetId: '' },
        currentSheetLevel: BudgetLevels.FIRST_LEVEL,
      });
    }
    if (currentSheetLevel === BudgetLevels.THIRD_LEVEL) {
      const rowIndex = findRowIndexByAccountId(
        selectedSheet.l2SheetId,
        SheetNames.L2,
        selectedSheet.l1SheetId,
      );

      // updating title page details
      if (!dataSheet) return;
      const prevSelectLevel1SheetTitleDetails = handleLevel2PageTitleDetails(
        dataSheet.meta?.file.projectName ?? '',
        dataSheet.l1,
        selectedSheet,
      );
      if (!prevSelectLevel1SheetTitleDetails) return;

      setPageTitleDetails(prevSelectLevel1SheetTitleDetails);

      if (costTrackerMode) {
        setHighlightCells({
          startRow: (currentL3FilterIndex ?? 1) - 1 ?? 0,
          endRow: (currentL3FilterIndex ?? 1) - 1 ?? 0,
          startCol: L2aCostTrackerColumnIndexes.account,
          endCol: L2aCostTrackerColumnIndexes.account,
        });
      } else {
        setHighlightCells({
          startRow: rowIndex,
          endRow: rowIndex,
          startCol: VisibleL2ColumnIndexes.account,
          endCol: VisibleL2ColumnIndexes.account,
        });
      }

      onNavigationSuccess?.({
        newSelectedSheet: { ...selectedSheet, l2SheetId: '' },
        currentSheetLevel: BudgetLevels.SECOND_LEVEL,
      });
    }
  };

  const handleClickDrillDown = () => {
    if (!dataSheet) return;
    if (!!selectedIndexes && selectedIndexes?.length <= 0) return;
    setHighlightCells({ startRow: -1, endRow: -1, startCol: -1, endCol: -1 });

    if (
      currentSheetLevel === BudgetLevels.FIRST_LEVEL &&
      selectedSheet.l1SheetId !== '' &&
      dataSheet.l1?.[selectedIndexes![0]]?.rowType === RowTypes.D
    ) {
      // updating title page details
      const prevSelectLevel1SheetTitleDetails = handleLevel2PageTitleDetails(
        dataSheet.meta?.file.projectName ?? '',
        dataSheet.l1,
        selectedSheet,
      );
      if (!prevSelectLevel1SheetTitleDetails) return;

      setPageTitleDetails(prevSelectLevel1SheetTitleDetails);

      handleL1Navigation(selectedSheet.l1SheetId);
    }

    if (
      currentSheetLevel === BudgetLevels.SECOND_LEVEL &&
      selectedSheet.l2SheetId !== '' &&
      dataSheet.l2[selectedSheet.l1SheetId][selectedIndexes![0]]?.rowType === RowTypes.D
    ) {
      // updating title page details
      const prevSelectLevel2SheetTitleDetails = handleLevel3PageTitleDetails(
        dataSheet.meta?.file.projectName ?? '',
        dataSheet.l2,
        selectedSheet,
      );
      if (!prevSelectLevel2SheetTitleDetails) return;

      setPageTitleDetails(prevSelectLevel2SheetTitleDetails);

      handleL2Navigation(selectedSheet.l2SheetId);
    }

    if (
      costTrackerMode &&
      currentSheetLevel === BudgetLevels.SECOND_LEVEL &&
      selectedSheet.l2SheetId !== '' &&
      rawCTL2aData[selectedIndexes![0]][L2aCostTrackerColumnIndexes.rowType + 1] === RowTypes.D
    ) {
      const l2SheetId = rawCTL2aData[selectedIndexes![0]][0];
      if (typeof l2SheetId !== 'string') return;

      const prevSelectLevel2SheetTitleDetails = handleLevel3PageTitleDetails(
        dataSheet.meta?.file.projectName ?? '',
        dataSheet.l2,
        {
          l1SheetId: selectedSheet.l1SheetId,
          l2SheetId: l2SheetId,
          l3SheetId: '',
        },
      );
      if (!prevSelectLevel2SheetTitleDetails) return;

      setPageTitleDetails(prevSelectLevel2SheetTitleDetails);
      handleL2Navigation(l2SheetId);
    }
  };

  const isNavigationDisabled = (direction: NavigationType): boolean => {
    const { FIRST_LEVEL, SECOND_LEVEL, THIRD_LEVEL } = BudgetLevels;

    if (!dataSheet || !selectedSheet || currentSheetLevel === FIRST_LEVEL) return true;

    if (currentSheetLevel === SECOND_LEVEL) {
      return isLevelNavigationDisabled(SECOND_LEVEL, selectedSheet.l1SheetId, direction);
    }

    if (currentSheetLevel === THIRD_LEVEL) {
      const isL2Disabled = isLevelNavigationDisabled(
        THIRD_LEVEL,
        selectedSheet.l2SheetId,
        direction,
      );

      if (isL2Disabled) {
        return isLevelNavigationDisabled(SECOND_LEVEL, selectedSheet.l1SheetId, direction);
      }
    }

    return false;
  };

  const handleBudgetAccountNavigation = (navType: NavigationType) => {
    if (!dataSheet || !selectedSheet) return;

    const { selectedIdKey } = getLevelConfig(currentSheetLevel);
    const allRowsInCurrentLevel = getDataByLevel(currentSheetLevel);
    const currentIndex =
      allRowsInCurrentLevel?.findIndex(({ id }) => id === selectedSheet[selectedIdKey]) ?? -1;
    const isEdgeRow =
      (currentIndex + 1 === allRowsInCurrentLevel?.length && navType === NavigationType.NEXT) ||
      (currentIndex === 0 && navType === NavigationType.PREVIOUS);

    if (!costTrackerMode && currentSheetLevel === BudgetLevels.THIRD_LEVEL && isEdgeRow) {
      handleL3Navigation(navType);
      return;
    }

    if (costTrackerMode && currentSheetLevel === BudgetLevels.THIRD_LEVEL) {
      handleCostTrackerNavigation(navType);
      return;
    }

    const nextRowIndex = currentIndex + (navType === NavigationType.NEXT ? 1 : -1);
    const nextRowId = allRowsInCurrentLevel[nextRowIndex]?.id.toString();
    const newSelectedSheet = {
      ...selectedSheet,
      [selectedIdKey]: nextRowId,
    };

    if (currentSheetLevel === BudgetLevels.SECOND_LEVEL) {
      newSelectedSheet.l2SheetId =
        (dataSheet.l2?.[nextRowId]?.[0]?.id as string) ?? newSelectedSheet.l2SheetId;
      setHighlightCells({ startRow: -1, endRow: -1, startCol: -1, endCol: -1 });
    }

    onNavigationSuccess?.({
      newSelectedSheet,
      currentSheetLevel,
    });
  };

  return {
    isNavigationDisabled,
    handleAccountNavigation: (navigationType: NavigationType) =>
      handleBudgetAccountNavigation(navigationType),
    handleClickDrillUp,
    handleClickDrillDown,
    handleL1Navigation,
    handleL2Navigation,
  };
};

export default useBudgetNavigation;
