import { SheetNames } from '@/enums';
import { DateSheetAttributeType, IDataSheet, initialConfigs } from '@/interfaces/IDataSheet';
import { initialFileAttachmentList } from '@/interfaces/IFileAttachments';
import { initialMeta } from '@/interfaces/IMetaData';

export const createInitialImportDataSheet = (): IDataSheet => ({
  [DateSheetAttributeType.META]: initialMeta,
  [DateSheetAttributeType.SHEET_NAMES]: {
    l2: [],
    l3: [],
  },
  [DateSheetAttributeType.L1]: [],
  [DateSheetAttributeType.L2]: {},
  [DateSheetAttributeType.L3]: {},
  [DateSheetAttributeType.UNIT_DESCRIPTIONS]: [],
  [DateSheetAttributeType.GLOBALS]: [],
  [DateSheetAttributeType.CURRENCY]: [],
  [DateSheetAttributeType.FRINGES]: [],
  [DateSheetAttributeType.SETS]: [],
  [DateSheetAttributeType.GROUPS]: [],
  [DateSheetAttributeType.COSTS_TO_DATE]: [],
  [DateSheetAttributeType.CONFIGS]: initialConfigs,
  [DateSheetAttributeType.LOCATIONS]: [],
  [DateSheetAttributeType.FILE_ATTACHMENTS]: initialFileAttachmentList,
  [SheetNames.NOTES]: {},
});
