class UniqueObjectSet<T extends object, K extends keyof T> {
  private map: Map<T[K], T>;

  constructor(private key: K) {
    this.map = new Map();
  }

  add(obj: T): void {
    const keyValue = obj[this.key];
    if (!this.map.has(keyValue)) {
      this.map.set(keyValue, obj);
    }
  }

  delete(obj: T): void {
    const keyValue = obj[this.key];
    this.map.delete(keyValue);
  }

  has(obj: T): boolean {
    const keyValue = obj[this.key];
    return this.map.has(keyValue);
  }

  get(keyValue: T[K]): T | undefined {
    return this.map.get(keyValue);
  }

  size(): number {
    return this.map.size;
  }

  values(): T[] {
    return Array.from(this.map.values());
  }
}

export default UniqueObjectSet;
