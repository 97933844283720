import { IDataSheet } from '@/interfaces/IDataSheet';

abstract class FileImporter {
  abstract validate(fileContent: unknown): boolean;
  abstract parse(fileContent: string): unknown;
  abstract mapToDataSheet(parsedData: unknown): IDataSheet;

  public import(fileContent: string): IDataSheet {
    const parsedData = this.parse(fileContent);

    if (!this.validate(parsedData)) {
      throw new Error('Invalid file format');
    }

    return this.mapToDataSheet(parsedData);
  }
}

export default FileImporter;
