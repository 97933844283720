import styled from '@emotion/styled';
import React from 'react';

export const StyledCode = styled.code`
  display: inline-block;
  padding: 0 5px;
  color: var(--muidocs-palette-text-primary, #1a2027);
  background-color: rgba(102, 178, 255, 0.15);
  border-radius: 5px;
  font-size: 1rem;
  direction: ltr;
`;

function NamedExpressionRules() {
  return (
    <ul>
      <li>
        Must start with a Unicode letter or with an underscore (<StyledCode>_</StyledCode>).
      </li>{' '}
      <li>
        Can contain only Unicode letters, numbers, underscores, and periods (
        <StyledCode>.</StyledCode>).
      </li>{' '}
      <li>
        Can't be the same as any possible reference in the A1 notation (for example,
        <StyledCode>Q4</StyledCode> or <StyledCode>YEAR2023</StyledCode>).
      </li>{' '}
      <li>
        Can't be the same as any possible reference in the R1C1 notation (for example,
        <StyledCode>R4C5</StyledCode>, <StyledCode>RC</StyledCode> or <StyledCode>R0C</StyledCode>).
      </li>{' '}
      <li>
        Must be <strong>unique</strong>.
      </li>
    </ul>
  );
}

export default NamedExpressionRules;
