import * as yup from 'yup';

export type JSONData = yup.InferType<typeof jsonFileSchema>;
export type FringeDetail = yup.InferType<typeof fringeDetailSchema>;

export enum FringeTypes {
  pctFringe = 'pctFringe',
  flatFringe = 'flatFringe',
}

const fringeDetailSchema = yup
  .array()
  .of(
    yup.object({
      type: yup.mixed<FringeTypes>().oneOf(Object.values(FringeTypes)),
      name: yup.string().required(),
      rate: yup.number().required(),
      unit: yup.string().notRequired(),
    }),
  )
  .optional();

export const jsonFileSchema = yup.object({
  metadata: yup
    .object({
      budgetdata: yup
        .object()
        .shape({
          BudgetName: yup.string().required(),
          ProjectName: yup.string().required(),
          BaseCurrency: yup.string().required(),
          ConvCurrency: yup.string().required(),
          Created: yup.string().notRequired(),
          Modified: yup.string().notRequired(),
        })
        .required(),
    })
    .required(),
  categories: yup
    .array()
    .of(
      yup
        .object({
          cID: yup.number().required(),
          cNumber: yup.string().required(),
          cDescription: yup.string().required(),
          cRowType: yup.string().notRequired(),
          cFringe: yup.number().nullable(),
          cTotal: yup.number().nullable(),
          fringeDetail: fringeDetailSchema,
        })
        .required(),
    )
    .min(1)
    .required(),
  accounts: yup
    .array()
    .of(
      yup
        .object({
          categoryID: yup.number().required(),
          aID: yup
            .mixed()
            .test(
              'is-string-or-number',
              'Account id must be a string or a number',
              (value) => typeof value === 'string' || typeof value === 'number',
            )
            .required(),
          aNumber: yup.string(),
          aDescription: yup.string(),
          aRowType: yup.string().notRequired(),
          fringeDetail: fringeDetailSchema,
        })
        .required(),
    )
    .required(),
  details: yup
    .array()
    .of(
      yup
        .object({
          accountID: yup
            .mixed()
            .test(
              'is-string-or-number',
              'Account id must be a string or a number',
              (value) => typeof value === 'string' || typeof value === 'number',
            )
            .required(),
          dGlobalsUsed: yup.array().of(yup.string()).min(0),
          dFringes: yup.array().of(yup.string()).min(0),
          dGroups: yup.array().of(yup.string()).min(0),
          dLocation: yup.string(),
          dSet: yup.string(),
          dDescription: yup.string(),
          dAmount: yup.number().nullable(),
          dUnit: yup.string(),
          dX: yup.number().nullable(),
          dRate: yup.number().nullable(),
          dCurrency: yup.string(),
          fringeDetail: fringeDetailSchema,
        })
        .required(),
    )
    .required(),
});
