import { L1ColumnIndexes, L2ColumnIndexes, RowTypes } from '@/enums';
import { IL1Sheet, IL2Sheet } from '@/interfaces/IFormulaSheet';

export const extractFromDataSheet = (
  columnIndex: L1ColumnIndexes | L2ColumnIndexes,
  data?: IL1Sheet | IL2Sheet,
  allowedRowTypes: RowTypes[] = [RowTypes.D],
) =>
  Array.from(
    data?.reduce((accumulator, rowData) => {
      const rowType = rowData[L2ColumnIndexes.rowType] as RowTypes;
      const account = rowData[L2ColumnIndexes.account] as string;

      if (allowedRowTypes.includes(rowType) && account !== '') {
        accumulator.add(rowData[columnIndex] as string);
      }

      return accumulator;
    }, new Set<string>()) ?? [],
  );
