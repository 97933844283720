import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { insertOptions, pasteSpecialOptions } from '@/constants';
import { IAggregationMapValue } from '@/interfaces/IAggregationMap';
import { ICellError } from '@/interfaces/ICellError';
import { ICostTrackerLevel2aGroupByOptions } from '@/interfaces/ICostTracker';
import { IDataSheet } from '@/interfaces/IDataSheet';
import { IL2Sheet } from '@/interfaces/IFormulaSheet';
import { ISelectedSheet } from '@/interfaces/ISelectedSheet';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: localStorage,
});

export const dataSheetAtom = atom<IDataSheet | null>({
  key: 'dataSheetAtom',
  default: null,
});

export const aggregationsAtom = atom<Map<string, Map<string, IAggregationMapValue>> | null>({
  key: 'aggregationsAtom',
  default: null,
});
export const isRecalculatingAggregationAtom = atom<boolean>({
  key: 'isRecalculatingAggregationAtom',
  default: false,
});

export const currentDataSheetAtom = atom<IDataSheet | null>({
  key: 'currentDataSheetAtom',
  default: null,
});

export const currentL3FilterIndexAtom = atom<number>({
  key: 'currentL3FilterIndexAtom',
  default: undefined,
});

export const rawCostTrackerL2aData = atom<IL2Sheet>({
  key: 'rawCostTrackerL2aDataAtom',
  default: [],
});

export const undoDataSheetAtom = atom<Array<IDataSheet | null>>({
  key: 'undoDataSheetAtom',
  default: [],
});

export const redoDataSheetAtom = atom<Array<IDataSheet | null>>({
  key: 'redoDataSheetAtom',
  default: [],
});

export const selectedSpecialPasteAtom = atom<string[]>({
  key: 'selectedSpecialPasteAtom',
  default: pasteSpecialOptions
    .map(({ value }) => value)
    // added default value from pasteSpecialOption disable paste-special.
    .filter((value) => value !== 'paste-special'),
  effects_UNSTABLE: [persistAtom],
});

export const selectedInsertOptionsAtom = atom<string[]>({
  key: 'selectedInsertOptionsAtom',
  default: insertOptions.map(({ value }) => value),
  effects_UNSTABLE: [persistAtom],
});

export const initialSelectedSheet = {
  l1SheetId: '',

  l2SheetId: '',

  l3SheetId: '',
} as ISelectedSheet;

export const selectedSheetIdsAtom = atom<ISelectedSheet>({
  key: 'selectedSheetIds',
  default: initialSelectedSheet,
});

export const addDataToCTDatom = atom<any>({
  key: 'addDataToCTDatom',
  default: null,
});

export const selectedCTL2aGroupByOptionsAtom = atom<ICostTrackerLevel2aGroupByOptions>({
  key: 'selectedCTL2aGroupByOptionsAtom',
  default: {
    episode: true, // episode field should always be true
    currency: true,
    location: true,
    set: true,
  },
  effects_UNSTABLE: [persistAtom],
});

export const cellErrorDialogAtom = atom<boolean>({
  key: 'cellErrorDialogAtom',
  default: false,
});

export const cellErrorDetailsAtom = atom<ICellError[]>({
  key: 'cellErrorDetailsAtom',
  default: [],
});

export const cellErrorLocationAtom = atom<string>({
  key: 'cellErrorLocationAtom',
  default: '',
});
