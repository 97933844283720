import { ValidationError } from 'yup';
import { RowTypes } from '@/enums';
import { createInitialImportDataSheet, generatedId, UniqueObjectSet } from '@/utils';
import { FringeAllocationDataKeys } from '@/data/meta/FringeAllocationData';
import type { IL1Data, IL2Data, IL3Data, IDataSheet } from '@/interfaces/IDataSheet';
import { logger } from '@/lib/logger';

import FileImporter from './importer.abstract';
import FileImportHelper from './importer.helper';
import { type FringeDetail, FringeTypes, type JSONData, jsonFileSchema } from './json.schema';

type NamedExpressionGroupType =
  | 'fringes'
  | 'groups'
  | 'globals'
  | 'sets'
  | 'locations'
  | 'currency'
  | 'unit_desc';

class JSONStrategy extends FileImporter {
  protected helper = new FileImportHelper();

  private currency = new UniqueObjectSet<{ code: string; value: number }, 'code'>('code');
  private unitDesc = new UniqueObjectSet<{ code: string; value: number }, 'code'>('code');
  private sets = new UniqueObjectSet<{ code: string }, 'code'>('code');
  private locations = new UniqueObjectSet<{ code: string }, 'code'>('code');
  private globals = new UniqueObjectSet<{ code: string; value: number }, 'code'>('code');
  private groups = new UniqueObjectSet<{ code: string }, 'code'>('code');
  private fringes = new UniqueObjectSet<{ code: string; unit: string; value: number }, 'code'>(
    'code',
  );
  private tempFringes = new UniqueObjectSet<{ code: string; unit: string; value: number }, 'code'>(
    'code',
  );

  private namedExpressions = new Map<string, string>();
  private namedExpressionsConflicts: Array<{
    group: NamedExpressionGroupType;
    prevName: string;
    newName: string;
    accountId: string;
    detailId: string;
  }> = [];

  public parse(fileContent: string): unknown {
    return JSON.parse(fileContent);
  }

  public validate(parsedData: unknown): boolean {
    try {
      jsonFileSchema.validateSync(parsedData, { abortEarly: false });
      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        logger.info('[ImportService] Validation errors have occurred:', error.errors);
      }
      return false;
    }
  }

  public mapToDataSheet(validatedData: JSONData): IDataSheet {
    const dataSheet = createInitialImportDataSheet();

    this.mapCategoriesToL1DataSheet(validatedData.categories, dataSheet, validatedData);
    this.mapAccountsToL2DataSheet(validatedData.accounts, dataSheet);
    this.mapDetailsToL3DataSheet(validatedData.details, dataSheet);
    this.mapNamedExpressionsToDataSheet(validatedData.metadata.budgetdata.BaseCurrency, dataSheet);
    this.mapMetadataToDataSheet(validatedData.metadata, dataSheet);

    this.resolveNamedExpressionConflicts(dataSheet);

    logger.debug('[ImportService] Imported file has been mapped correctly! ', dataSheet);
    return dataSheet;
  }

  private mapCategoriesToL1DataSheet(
    categories: JSONData['categories'],
    dataSheet: IDataSheet,
    importedData: JSONData,
  ) {
    categories.forEach((category) => {
      let l1Object = {
        id: category.cID.toString(),
        account: category.cNumber,
        description: category.cDescription,
        rowType: category.cRowType ?? RowTypes.D,
      } satisfies IL1Data;

      if (category.cDescription === 'Total Fringes') {
        l1Object = {
          ...l1Object,
          account: '',
          rowType: RowTypes.F,
        };
      }

      dataSheet.l1.push(l1Object);

      this.addRebateRowIfExists(category, importedData);
      this.addFringeDetailsIfExists(
        dataSheet,
        category.fringeDetail,
        FringeAllocationDataKeys.BUDGET,
      );
    });

    this.addTotalFringeRowIfMissing<IL1Data>(1, dataSheet.l1);
    this.addTotalRowIfMissing<IL1Data>(1, dataSheet.l1);
  }

  private mapAccountsToL2DataSheet(accounts: JSONData['accounts'], dataSheet: IDataSheet) {
    accounts.forEach((account) => {
      const {
        categoryID,
        aID: l2Id,
        aNumber: accountCode,
        aDescription: description,
        aRowType: rowType,
        fringeDetail,
      } = account;

      let l2Object = {
        id: l2Id.toString(),
        account: accountCode ?? '',
        description: description ?? '',
        rowType: rowType ?? RowTypes.D,
      } satisfies IL2Data;

      if (description === 'Total Fringes') {
        l2Object = {
          ...l2Object,
          account: '',
          rowType: RowTypes.F,
        };
      }

      const l1Id = categoryID.toString();
      const isL1ObjectExists = dataSheet.l1.some((l1) => l1.id === l1Id);
      if (isL1ObjectExists) {
        dataSheet.l2[l1Id] = dataSheet.l2[l1Id] || [];
        dataSheet.l2[l1Id].push(l2Object);

        // Ensures the L2 id exists in L3
        if (!dataSheet.l3[l2Object.id] && l2Object.rowType === RowTypes.D) {
          dataSheet.l3[l2Object.id] = [];
          dataSheet.sheetNames.l3.push(l2Object.id);
        }

        // Adds the L1 id to sheetNames.l2 if it was newly initialized
        if (!dataSheet.sheetNames.l2.includes(l1Id)) {
          dataSheet.sheetNames.l2.push(l1Id);
        }
      }

      this.addFringeDetailsIfExists(dataSheet, fringeDetail, FringeAllocationDataKeys.CATEGORY);
    });

    // Adds total row with L1 description
    const l1DescriptionMap = new Map<string, string>();
    dataSheet.l1.forEach((l1) =>
      l1DescriptionMap.set(
        l1.id.toString(),
        l1.description ? `TOTAL FOR ${l1.description.toUpperCase()}` : 'TOTAL',
      ),
    );
    Object.entries(dataSheet.l2).forEach(([l1Id, l2Array]) => {
      const description = l1DescriptionMap.get(l1Id) || 'TOTAL';
      this.addTotalFringeRowIfMissing<IL2Data>(2, l2Array);
      this.addTotalRowIfMissing<IL2Data>(2, l2Array, description);
    });
  }

  private mapDetailsToL3DataSheet(details: JSONData['details'], dataSheet: IDataSheet) {
    // Needs to register currency and fringes keys first as a named expressions
    details.forEach((detail) => {
      this.addNamedExpression({
        group: 'currency',
        key: detail.dCurrency ?? '',
      });
      this.addFringeDetailsIfExists(
        dataSheet,
        detail.fringeDetail,
        FringeAllocationDataKeys.ACCOUNT,
      );
    });

    details.forEach((detail) => {
      let l3Object = {
        id: generatedId(),
        range: '',
        fringe: '',
        fringes:
          (detail.dFringes ?? []).length > 0
            ? (detail.dFringes ?? [])
                .map((code) => this.formatNamedExpressionCode(code ?? ''))
                .join(',')
            : '',
        groups:
          (detail.dGroups ?? []).length > 0
            ? (detail.dGroups ?? [])
                .map((code) => this.formatNamedExpressionCode(code ?? ''))
                .join(',')
            : '',
        loc: detail.dLocation ?? '',
        set: detail.dSet ?? '',
        description: detail.dDescription ?? '',
        units: detail.dAmount?.toString() ?? '',
        desc: detail.dUnit === '%' ? '%' : this.formatNamedExpressionCode(detail.dUnit ?? ''),
        x: detail.dX?.toString() ?? '',
        rate: detail.dRate?.toString() ?? '',
        cu: detail.dCurrency ?? '',
        comparison: 0,
        rowType: RowTypes.D,
        fringeComparison: 0,
      } satisfies IL3Data;

      if (detail.dDescription === 'Total Fringes') {
        l3Object = {
          ...l3Object,
          rowType: RowTypes.F,
        };
      }

      this.addNamedExpression({
        group: 'unit_desc',
        key: l3Object.desc,
        accountId: detail.accountID.toString(),
        detailId: l3Object.id,
      });
      this.addNamedExpression({
        group: 'sets',
        key: l3Object.set,
        accountId: detail.accountID.toString(),
        detailId: l3Object.id,
      });
      this.addNamedExpression({
        group: 'locations',
        key: l3Object.loc,
        accountId: detail.accountID.toString(),
        detailId: l3Object.id,
      });
      this.addNamedExpression({
        group: 'groups',
        key: l3Object.groups,
        accountId: detail.accountID.toString(),
        detailId: l3Object.id,
      });
      this.addNamedExpression({
        group: 'globals',
        key: (detail.dGlobalsUsed ?? [])
          .map((code) => this.formatNamedExpressionCode(code ?? ''))
          .join(','),
        accountId: detail.accountID.toString(),
        detailId: l3Object.id,
      });

      l3Object.fringes.split(',').forEach((code) => {
        if (!code) return;

        const fringe = this.tempFringes.get(code);
        if (fringe) {
          this.addNamedExpression({
            group: 'fringes',
            key: code,
            fringeOptions: { unit: fringe.unit, value: fringe.value },
            accountId: detail.accountID.toString(),
            detailId: l3Object.id,
          });
        } else {
          throw new Error(
            'One of the detail use undefined fringe code. \nImported file is corrupted.',
          );
        }
      });

      const l2Id = detail.accountID.toString();
      dataSheet.l3[l2Id].push(l3Object);
    });

    // Adds total row with L2 description
    const l2DescriptionMap = new Map<string, string>();
    Object.entries(dataSheet.l2).forEach(([l1Id, l2Array]) => {
      l2Array.forEach((l2) => {
        if (l2.description) {
          l2DescriptionMap.set(l2.id.toString(), `TOTAL FOR ${l2.description.toUpperCase()}`);
        }
      });
    });
    Object.entries(dataSheet.l3).forEach(([l2Id, l3Array]) => {
      const description = l2DescriptionMap.get(l2Id) || 'TOTAL';
      this.addTotalFringeRowIfMissing<IL3Data>(3, l3Array);
      this.addTotalRowIfMissing<IL3Data>(3, l3Array, description);
    });
  }

  private mapNamedExpressionsToDataSheet(
    baseCurrency: JSONData['metadata']['budgetdata']['BaseCurrency'],
    dataSheet: IDataSheet,
  ) {
    dataSheet.currency = this.helper.createCurrencies(this.currency, baseCurrency);
    dataSheet.unitDesc = this.helper.createUnitDescriptions(this.unitDesc);
    dataSheet.sets = this.helper.createSets(this.sets);
    dataSheet.locations = this.helper.createLocations(this.locations);
    dataSheet.groups = this.helper.createGroups(this.groups);
    dataSheet.globals = this.helper.createGlobals(this.globals);
    dataSheet.fringes = this.helper.createFringes(this.fringes);
  }

  private mapMetadataToDataSheet(metadata: JSONData['metadata'], dataSheet: IDataSheet) {
    const { BudgetName, ProjectName, Created, Modified, BaseCurrency, ConvCurrency } =
      metadata.budgetdata;

    dataSheet.meta.notes = `${BudgetName}-`;
    dataSheet.meta.projectName = ProjectName;

    if (dataSheet.meta.file) {
      dataSheet.meta.file.projectName = BudgetName;
      dataSheet.meta.file.note = `${BudgetName}-`;
      dataSheet.meta.file.created = Created ?? '';
      dataSheet.meta.file.lastModified = Modified ?? '';
    }

    if (dataSheet.meta.fringes) {
      dataSheet.meta.fringes.cutoff = 1;
    }

    if (dataSheet.meta.accountFormat) {
      dataSheet.meta.accountFormat.categoryDigits = 4;
      dataSheet.meta.accountFormat.accountDigits = 4;
      dataSheet.meta.accountFormat.setDigits = 4;
      dataSheet.meta.accountFormat.separator = '';
    }

    if (dataSheet.meta.currencyImport) {
      dataSheet.meta.currencyImport.baseCurrency = BaseCurrency;
      dataSheet.meta.currencyImport.convCurrency = ConvCurrency;
    }

    dataSheet.configs.dropCurrencyAtReset = ConvCurrency;
  }

  private resolveNamedExpressionConflicts(dataSheet: IDataSheet) {
    if (this.namedExpressionsConflicts.length === 0) return dataSheet;

    this.namedExpressionsConflicts
      .filter(({ group }) => group !== 'globals')
      .forEach(({ accountId, detailId, group, newName, prevName }) => {
        dataSheet.l3[accountId].forEach((detail) => {
          if (detail.id === detailId) {
            const columnKey = group as keyof IL3Data;
            if (typeof detail[columnKey] === 'string') {
              detail[columnKey] = (detail[columnKey] as string).replace(prevName, newName);
            }
          }
        });
      });

    logger.debug(
      `[ImportService] Found and resolved "${this.namedExpressionsConflicts.length}" conflicts in the imported file`,
    );
  }

  private addNamedExpression(arg: {
    group: NamedExpressionGroupType;
    key: string;
    fringeOptions?: { unit: string; value: number };
    detailId?: string;
    accountId?: string;
  }) {
    const { group, key, fringeOptions, detailId, accountId } = arg;
    if (key === '') return;
    if (key === '%') return;

    key.split(',').forEach((originalKey) => {
      let uniqueKey = originalKey.trim();
      let counter = 1;

      // Only checks for conflicts if the group is not excluded
      if (!['unit_desc', 'sets', 'locations'].includes(group)) {
        // Ensures the key is unique across all relevant groups (but not within the same group)
        while (
          this.namedExpressions.has(uniqueKey) &&
          this.namedExpressions.get(uniqueKey) !== group
        ) {
          uniqueKey = `${originalKey.trim()}_${counter++}_`;
        }

        if (uniqueKey !== originalKey && detailId && accountId) {
          this.namedExpressionsConflicts.push({
            detailId,
            accountId,
            group,
            prevName: originalKey.trim(),
            newName: uniqueKey,
          });
        }

        this.namedExpressions.set(uniqueKey, group);
      }

      switch (group) {
        case 'fringes': {
          this.fringes.add({
            code: uniqueKey,
            unit: fringeOptions?.unit || '',
            value: fringeOptions?.value || 0,
          });
          break;
        }
        case 'groups': {
          uniqueKey.split(',').forEach((code) => this.groups.add({ code }));
          break;
        }
        case 'globals': {
          uniqueKey.split(',').forEach((code) =>
            this.globals.add({
              code,
              value: 0,
            }),
          );
          break;
        }
        case 'sets': {
          this.sets.add({ code: uniqueKey });
          break;
        }
        case 'locations': {
          this.locations.add({ code: uniqueKey });
          break;
        }
        case 'currency': {
          this.currency.add({ code: uniqueKey, value: 1 });
          break;
        }
        case 'unit_desc': {
          this.unitDesc.add({ code: uniqueKey, value: 1 });
          break;
        }
        default:
          throw new Error(`Unknown group: ${group}`);
      }
    });
  }

  private addTotalFringeRowIfMissing<T extends { rowType: string }>(
    level: 1 | 2 | 3,
    dataArray: T[],
  ) {
    const hasTotalFringeRow = dataArray.some((obj) => obj.rowType === RowTypes.F);

    if (!hasTotalFringeRow) {
      let totalFringeRow;
      switch (level) {
        case 1:
        case 2:
          totalFringeRow = this.helper.getLevel2EmptyTotalFringeRow();
          break;
        case 3:
          totalFringeRow = this.helper.getLevel3EmptyTotalFringeRow();
          break;
      }

      dataArray.push(totalFringeRow as unknown as T);
    }
  }

  private addTotalRowIfMissing<T extends { rowType: string }>(
    level: 1 | 2 | 3,
    dataArray: T[],
    description: string = 'TOTAL',
  ) {
    const hasTotalRow = dataArray.some((obj) => obj.rowType === RowTypes.T);

    if (!hasTotalRow) {
      let totalRow;
      switch (level) {
        case 1:
        case 2:
          totalRow = this.helper.getLevel2EmptyTotalRow();
          totalRow.description = description;
          break;
        case 3:
          totalRow = this.helper.getLevel3EmptyTotalRow();
          totalRow.description = description;
          break;
      }

      dataArray.push(totalRow as unknown as T);
    }
  }

  private addRebateRowIfExists(category: JSONData['categories'][number], importedData: JSONData) {
    if (category.cFringe !== null) return;

    const accountId = generatedId();
    const {
      cID: categoryID,
      cDescription: description,
      cNumber: accountNumber,
      cTotal: rate,
    } = category;

    importedData.accounts.push({
      aID: accountId,
      categoryID,
      aDescription: description,
      aNumber: accountNumber,
    });

    importedData.details.push({
      accountID: accountId,
      dDescription: description,
      dAmount: 1,
      dX: 1,
      dRate: rate ?? 1,
      dFringes: [],
      dGroups: [],
      dGlobalsUsed: [],
      dLocation: '',
      dSet: '',
      dUnit: '',
      dCurrency: '',
    });
  }

  private addFringeDetailsIfExists(
    dataSheet: IDataSheet,
    fringeDetails?: FringeDetail,
    fringeDetailsPostedBy: FringeAllocationDataKeys = FringeAllocationDataKeys.ACCOUNT,
  ) {
    // Temporarily adds account fringes for reference when mapping details to L3
    fringeDetails?.forEach(({ type, name, rate, unit }) => {
      this.tempFringes.add({
        code: this.formatNamedExpressionCode(name),
        unit: type === FringeTypes.pctFringe ? '%' : unit || '',
        value: rate,
      });
    });

    if (
      fringeDetails !== undefined &&
      dataSheet.meta.fringes !== undefined &&
      dataSheet.meta.fringes.calc !== fringeDetailsPostedBy
    ) {
      dataSheet.meta.fringes.calc = fringeDetailsPostedBy;
    }
  }

  private formatNamedExpressionCode(code: string) {
    if (!code) return '';

    let newCode = code.toUpperCase();

    // Add letter “X” to the start of codes if starting with a number.
    if (code[0].match(/[0-9]/)) {
      newCode = 'X' + newCode;
    }
    // Ensure it starts with a Unicode letter or underscore.
    if (!/^[a-zA-Z_]/.test(newCode)) {
      newCode = '_' + newCode;
    }
    // Append underscore if the code ends with a number (to avoid A1 or R1C1 conflicts).
    if (/[0-9]$/.test(newCode)) {
      newCode = newCode + '_';
    }
    // Ensure the code does not resemble A1 or R1C1 notation.
    if (/^[A-Z]+\d+$/.test(newCode) || /^[A-Z]\d+[A-Z]\d+$/.test(newCode)) {
      newCode += '_';
    }
    // Replace any spaces with periods (full stops).
    newCode = newCode.replace(/ /g, '.');
    // Remove any comma in code.
    newCode = newCode.replace(/,/g, '');
    // Replace any invalid symbols like @, $, #, etc. with underscores.
    newCode = newCode.replace(/[^a-zA-Z0-9_.]/g, '_');

    return newCode;
  }
}

export default JSONStrategy;
