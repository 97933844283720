import { type IDataSheet } from '@/interfaces/IDataSheet';
import FileImporter from './importer.abstract';
import JSONStrategy from './json.strategy';
import XMLStrategy from './xml.strategy';

class ImportService {
  private importer: FileImporter;

  constructor(fileType: 'json' | 'xml') {
    switch (fileType) {
      case 'json':
        this.importer = new JSONStrategy();
        break;
      case 'xml':
        this.importer = new XMLStrategy();
        break;
      default:
        throw new Error('Unsupported file type');
    }
  }

  public importFile(fileContent: string): IDataSheet {
    return this.importer.import(fileContent);
  }
}

export default ImportService;
