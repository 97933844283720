import { CellValue } from 'hyperformula';
import lodash from 'lodash';
import {
  L1ColumnIndexes,
  L2ColumnIndexes,
  L2aCostTrackerColumnIndexes,
  L3ColumnIndexes,
  RowTypes,
  SheetNames,
} from '@/enums';
import { generatedId } from '@/utils';
import { formatTotalDescription } from '@/helpers/DataSheetHelper';
import { getLevel1SheetBySelectedLevel2Sheet } from '@/helpers/L1Helpers';
import {
  ICostTrackerL2aRow,
  ICostTrackerLevel2aData,
  ICostTrackerLevel2aGroupByOptions,
} from '@/interfaces/ICostTracker';
import { IDataSheet, IL1DataSheet, IL2Data, IL2DataSheet } from '@/interfaces/IDataSheet';
import { IL2, IL2Sheet, IL3Sheet } from '@/interfaces/IFormulaSheet';
import { ISelectedSheet } from '@/interfaces/ISelectedSheet';
import { IPageTitleDetails } from '@/interfaces/sheetTitleTypes';
import { COST_TRACKER_L2_ROWS_LENGTH } from '../constants/costTracker';

export function getLevel2SheetBySelectedLevel3Sheet(
  level2Sheet: IL2DataSheet,
  selectedSheet: ISelectedSheet,
): IL2Data | null {
  const selectedLevel2Sheets = level2Sheet[selectedSheet.l1SheetId];
  const selectedSheetGroupById = lodash.groupBy(selectedLevel2Sheets, 'id');
  const currentLevel2 = selectedSheetGroupById[selectedSheet.l2SheetId];
  if (lodash.isEmpty(currentLevel2)) return null;

  return selectedSheetGroupById[selectedSheet.l2SheetId][0];
}

export function handleLevel2PageTitleDetails(
  fileName: string,
  level1Sheet: IL1DataSheet,
  selectedSheet: ISelectedSheet,
): IPageTitleDetails | null {
  const prevSelectLevel1Sheet = getLevel1SheetBySelectedLevel2Sheet(level1Sheet, selectedSheet);
  if (lodash.isEmpty(prevSelectLevel1Sheet)) return null;

  return {
    fileName: fileName,
    accountNumber: prevSelectLevel1Sheet.account?.toString() ?? '',
    fullDescription: prevSelectLevel1Sheet.description?.toString() ?? '',
    selectedSheetName: prevSelectLevel1Sheet.description?.toString() ?? '',
  };
}

export function getLevel2Description(account: string | null, description: string | null): string {
  return formatTotalDescription(account, description);
}

export function getLevel1SheetIdByLevel2Id(
  level2Sheet: IL2DataSheet,
  level2Id: string,
): string | undefined {
  const level2SheetIndex = createLevel2SheetIndex(level2Sheet);
  return level2SheetIndex[level2Id];
}

export function createLevel2SheetIndex(level2Sheet: IL2DataSheet): { [id: string]: string } {
  const index: { [id: string]: string } = {};
  for (const level2Data in level2Sheet) {
    const arr = level2Sheet[level2Data];
    for (const item of arr) {
      index[item.id] = level2Data;
    }
  }
  return index;
}

export const getLevel2RequestedTypeValues = <T>(
  requestedType: L2ColumnIndexes,
  allValueSheetValues: Record<string, CellValue[][]>,
): Array<T> => {
  const level1SheetValues = allValueSheetValues[SheetNames.L1];
  const level1Ids = level1SheetValues.map((rowValues) => rowValues[L1ColumnIndexes.id]);

  const level2RequestedTypeValues: Array<T> = [];
  level1Ids.forEach((level1Id) => {
    const level2SheetValues = allValueSheetValues[`${SheetNames.L2}_${level1Id}`];
    if (!level2SheetValues) return false;
    level2SheetValues?.forEach((rowValues, rowNumber) => {
      const value = rowValues[requestedType];
      if (!value) return;
      level2RequestedTypeValues.push(value as T);
    });
  });
  return level2RequestedTypeValues;
};

const addSumRow = (
  data: IL2Sheet,
  totalTextSuffix: string,
  totalFringes: number,
  isFringePostedByCategory: boolean,
) => {
  const sums = new Array(11).fill(0);
  data.forEach((row) => {
    // If rowType is not 'D' return
    if (row[L2aCostTrackerColumnIndexes.rowType + 1] !== RowTypes.D) return;

    row.forEach((cell, index) => {
      // Only sums columns from 'Actual this Period' to 'EFCW'
      if (
        index >= L2aCostTrackerColumnIndexes.actual &&
        index <= L2aCostTrackerColumnIndexes.rowType &&
        typeof cell === 'number'
      ) {
        const value = isNaN(cell) ? 0 : cell;
        sums[index - L2aCostTrackerColumnIndexes.actual] += value;
      }
    });
  });

  const offset = 6;
  sums[L2aCostTrackerColumnIndexes.estimateToComplete - offset] += totalFringes;
  sums[L2aCostTrackerColumnIndexes.estimatedFinalCost - offset] += totalFringes;
  sums[L2aCostTrackerColumnIndexes.varianceToDate - offset] += totalFringes;
  sums[L2aCostTrackerColumnIndexes.varianceThisPeriod - offset] += -totalFringes;

  return [
    generatedId(),
    '',
    '',
    '',
    '',
    '',
    `TOTAL FOR ${totalTextSuffix}`,
    ...sums,
    'T',
    isFringePostedByCategory ? data.length + 2 : data.length + 1,
    0,
  ];
};

const applyMaskToAccountCode = (
  remoteData: {
    account: string;
    detl: string;
    set: string;
  },
  mask: string,
): string => {
  const { account, detl, set } = remoteData;

  let accountCode = '';
  let accountCharIndex = 0;
  let detlCharIndex = 0;
  let setCharIndex = 0;

  for (const maskChar of mask) {
    switch (maskChar) {
      case 'P':
        accountCode += account[accountCharIndex] ?? ' ';
        accountCharIndex++;
        break;
      case 'D':
        accountCode += detl[detlCharIndex] ?? ' ';
        detlCharIndex++;
        break;
      case 'S':
        accountCode += set[setCharIndex] ?? ' ';
        setCharIndex++;
        break;
      default:
        // Separator
        accountCode += maskChar;
        break;
    }
  }

  return accountCode;
};

const getAggregatedTotals = (
  data?: IL3Sheet,
  setKey?: string,
  locKey?: string,
  currencyKey?: string,
  groupBy: ICostTrackerLevel2aGroupByOptions = {
    set: false,
    location: false,
    currency: false,
    episode: true,
  },
): number => {
  if (!data || data.length === 0) return 0;

  let totalSum = 0;

  for (const row of data) {
    if (row[L3ColumnIndexes.rowType] !== RowTypes.D) continue;

    const {
      [L3ColumnIndexes.set]: set = '',
      [L3ColumnIndexes.loc]: loc = '',
      [L3ColumnIndexes.cu]: currency = '',
      [L3ColumnIndexes.total]: totalValue,
    } = row;

    const setMatches = groupBy.set ? true : set === setKey;
    const locMatches = groupBy.location ? true : loc === locKey;
    const currencyMatches = groupBy.currency ? true : currency === currencyKey;

    if (setMatches && locMatches && currencyMatches && typeof totalValue === 'number') {
      totalSum += totalValue;
    }
  }

  return totalSum;
};

export function transformBudgetToCostTrackerLevel2aData(
  budgetData: IL2Sheet,
  costTrackerData: Array<ICostTrackerLevel2aData>,
  useDetaAsSets: boolean,
  currentDataSheet: IDataSheet | null,
  getL3SheetValues: (l3SheetId: string | number) => IL3Sheet,
  mask: string,
  groupBy: ICostTrackerLevel2aGroupByOptions,
  totalTextSuffix: string = '',
) {
  let totalFringesCostTrackerRow: IL2 = [];
  const totalFringesBudgetRowIndex = budgetData.findIndex(
    (val) => val[L2ColumnIndexes.rowType] === RowTypes.F,
  );
  const totalFringesBudgetRow = budgetData?.[totalFringesBudgetRowIndex];
  const hasFringesRowInCtData = costTrackerData.some(
    (val) => totalFringesBudgetRow?.[L2ColumnIndexes.account] === val.Account,
  );

  const transformedData = costTrackerData
    .sort((a, b) => a.Account.localeCompare(b.Account))
    .reduce((sheetData, transaction, index) => {
      const accountCode = applyMaskToAccountCode(
        {
          account: transaction.Account ?? '',
          detl: transaction.Detl ?? '',
          set: transaction.Set ?? '',
        },
        mask,
      );
      const budgetDataRowIndex = lodash.findIndex(
        budgetData,
        (budgetDataRow) => budgetDataRow[L2ColumnIndexes.account] === accountCode,
      );

      if (budgetDataRowIndex === -1) return [];

      const budgetRow = budgetData[budgetDataRowIndex];
      const l2RowType = budgetRow[L2ColumnIndexes.rowType];
      const l2SheetId = budgetRow[L2ColumnIndexes.sheetId + 1];
      const l3SheetValues = l2SheetId ? getL3SheetValues(l2SheetId) : undefined;

      let estimatedFinalCost = getAggregatedTotals(
        l3SheetValues,
        useDetaAsSets ? transaction?.Detl : transaction?.Set,
        transaction.Location,
        transaction.CU,
        groupBy,
      );

      if (l2RowType === RowTypes.F) {
        estimatedFinalCost = Number(budgetRow[L2ColumnIndexes.total] ?? 0);
      }

      const costsToDate =
        (transaction?.Actual ?? 0) + (transaction?.Unposted ?? 0) + (transaction?.PO ?? 0);
      const estimateToComplete = estimatedFinalCost - costsToDate;
      const varianceToDate =
        currentDataSheet?.configs.varianceCalc === 'over'
          ? estimatedFinalCost - (transaction?.Budget ?? 0)
          : (transaction?.Budget ?? 0) - estimatedFinalCost;
      const varianceThisPeriod = (transaction?.EFCW ?? 0) - estimatedFinalCost;
      const underOver = Math.floor(varianceThisPeriod);

      const transformedRow: ICostTrackerL2aRow = {
        id: String(budgetRow[L2ColumnIndexes.id]),
        underOver: l2RowType === RowTypes.F ? 0 : underOver,
        cu: transaction?.CU ?? '',
        lo: transaction?.Location ?? '',
        account: accountCode,
        set: (useDetaAsSets ? transaction?.Detl : transaction?.Set) ?? '',
        description: transaction.Description ?? String(budgetRow[L2ColumnIndexes.description]),
        actualThisPeriod: transaction?.Period ?? 0,
        actual: transaction?.Actual ?? 0,
        processing: transaction?.Unposted ?? 0,
        purchaseOrders: transaction?.PO ?? 0,
        costsToDate: costsToDate,
        estimateToComplete: estimateToComplete,
        estimatedFinalCost: estimatedFinalCost,
        budget: transaction?.Budget ?? 0,
        varianceToDate: varianceToDate,
        varianceThisPeriod: varianceThisPeriod,
        efcw: transaction?.EFCW ?? 0,
        rowType: budgetRow[L2ColumnIndexes.rowType] as RowTypes,
        index: index + 1,
        fringeComparison: isNaN(Number(budgetRow[L2ColumnIndexes.fringeComparison]))
          ? null
          : Number(budgetRow[L2ColumnIndexes.fringeComparison]),
      };

      sheetData.push(Object.values(transformedRow));

      return sheetData;
    }, [] as IL2Sheet);

  if (totalFringesBudgetRowIndex !== -1 && !hasFringesRowInCtData) {
    totalFringesCostTrackerRow = new Array(COST_TRACKER_L2_ROWS_LENGTH).fill(0);

    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.cu + 1] = null;
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.loc + 1] = null;
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.account + 1] =
      totalFringesBudgetRow[L2ColumnIndexes.account];
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.set + 1] = null;
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.description + 1] =
      totalFringesBudgetRow[L2ColumnIndexes.description];

    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.estimateToComplete + 1] =
      totalFringesBudgetRow[L2ColumnIndexes.total];
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.estimatedFinalCost + 1] =
      totalFringesBudgetRow[L2ColumnIndexes.total];
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.varianceToDate + 1] =
      totalFringesBudgetRow[L2ColumnIndexes.total];
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.varianceThisPeriod + 1] =
      totalFringesBudgetRow[L2ColumnIndexes.total];

    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.rowType + 1] = RowTypes.F;
    totalFringesCostTrackerRow[L2aCostTrackerColumnIndexes.index + 1] = transformedData.length + 1;
  }

  const sumRow = addSumRow(
    transformedData,
    totalTextSuffix,
    Number(totalFringesBudgetRow?.[L1ColumnIndexes.total] ?? 0),
    totalFringesCostTrackerRow.length > 0,
  );

  return [
    ...transformedData,
    ...(totalFringesCostTrackerRow.length > 0 ? [totalFringesCostTrackerRow] : []),
    sumRow,
  ] as IL2Sheet;
}
