import { TypographyProps } from '@mui/material/Typography';

export const typographyStyles: TypographyProps['sx'] = {
  fontSize: '0.875rem',
  fontWeight: '600',
  textAlign: 'center',
  color: '#747474',
  opacity: 0.5,
  whiteSpace: 'pre-wrap',
};

export const boxStyles: Record<string, string | number> = {
  width: '264px',
  height: '150px',
  border: '1px dashed #D8D9E0',
  borderRadius: '8px',
  background: '#F1F3F4',
  boxShadow: '0 0 0 5px #F1F3F4',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: '5px',
};
